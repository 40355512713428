/** @jsx jsx */
import { jsx, Flex, Text } from "theme-ui";
import PropTypes from "prop-types";
import { getNextStandup } from "utils/details";
import getProjectColor from "utils/colors";
import { ReactComponent as CamIcon } from "images/cam.svg";
import { ReactComponent as PhoneIcon } from "images/phone.svg";
import CopyableValue from "components/CopyableValue";
import BlockLetter from "components/BlockLetter";

const ProjectVitals = ({ project }) => (
  <div
    sx={{
      position: "sticky",
      top: 4,
      width: "100%",
    }}
  >
    <div sx={{ marginBottom: 7 }}>
      <Flex sx={css.projectName}>
        {project.name && (
          <BlockLetter
            color={getProjectColor(project.id)}
            size={3}
            sx={{ marginRight: 2 }}
            letter={project.name.charAt(0)}
          />
        )}
        <Text>{project.name}</Text>
      </Flex>
      <div sx={{ marginTop: 3, opacity: "0.5" }}>
        Next {project.name}{" "}
        {getNextStandup(project.days || [], project.time, project.timezone)}
      </div>
    </div>
    <div sx={{ marginBottom: 7 }}>
      {project.meeting_url && (
        <CopyableValue
          linkType="http"
          label="Video"
          value={project.meeting_url}
          icon={<CamIcon alt="Icon for Video" />}
          codeLabel="Pin Code"
          codeValue={project.pin}
        />
      )}
    </div>
    <div sx={{ marginBottom: 7 }}>
      {project.phone_number && (
        <CopyableValue
          linkType="phone"
          label="Call-In"
          value={project.phone_number}
          icon={<PhoneIcon alt="Icon for Call-In" />}
          codeLabel="Call-In Code"
          codeValue={project.access_code}
        />
      )}
    </div>
  </div>
);

const css = {
  projectName: { flexDirection: "row", alignItems: "center" },
};

ProjectVitals.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectVitals;
