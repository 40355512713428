import { API_VERSION } from "constants/api";
import { UserList } from "schemas";
import { path, map } from "ramda";

export const PROJECT_USERS_LOAD = "PROJECT_USERS_LOAD";
export const PROJECT_USERS_LOAD_SUCCESS = "PROJECT_USERS_LOAD_SUCCESS";
export const PROJECT_USERS_LOAD_FAILURE = "PROJECT_USERS_LOAD_FAILURE";

const initialState = {};

export default function reducer(users = initialState, action = {}) {
  const { response, projectId } = action;

  switch (action.type) {
    case PROJECT_USERS_LOAD_SUCCESS: {
      const permissions = map(
        ({ is_owner, is_composer }) => ({ is_owner, is_composer }),
        path(["entities", "user"], response)
      );

      return {
        ...users,
        [projectId]: {
          users: response.result,
          permissions,
        },
      };
    }
    default: {
      return users;
    }
  }
}

export function getProjectUsers(projectId = 0) {
  return {
    types: [
      PROJECT_USERS_LOAD,
      PROJECT_USERS_LOAD_SUCCESS,
      PROJECT_USERS_LOAD_FAILURE,
    ],
    request: (api) => api.get(`/${API_VERSION}/projects/${projectId}/users`),
    schema: UserList,
    projectId,
  };
}
