/** @jsx jsx */
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { sortBy, prop, groupBy } from "ramda";
import { jsx, Text, Box } from "theme-ui";
import { STATUS_LABELS, STATUS_CLASSES } from "constants/api";
import { useDrop } from "react-dnd";
import { ItemTypes } from "utils/dragDropItems";
import Task from "components/Task";
import { Preview } from "react-dnd-multi-backend";

const generatePreview = ({ itemType, item, style }) =>
  itemType === ItemTypes.TASK && (
    <div style={style}>
      <Task task={item.task} readOnly={true} isPreview={true} />
    </div>
  );

const StatusContainer = ({
  groupId,
  targetStatus,
  onTaskReorder,
  children,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.TASK,
    canDrop: (item) => item.task.status !== targetStatus,
    drop: (item) => {
      onTaskReorder(item.task.position, { ...item.task, status: targetStatus });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <Box
      key={groupId}
      sx={{
        marginBottom: 6,
        border: isOver ? "1px solid black" : "none",
      }}
      ref={drop}
    >
      {children}
    </Box>
  );
};
const groupByStatus = groupBy((task) => task.status);

const sortByPosition = sortBy(prop("position"));

const TaskGroup = ({
  readOnly,
  tasks,
  onTaskChange,
  onTaskRemove,
  onTaskReorder,
}) => {
  const [stateTasks, setStateTasks] = useState(groupByStatus(tasks));

  useEffect(() => {
    setStateTasks(groupByStatus(tasks));
  }, [tasks]);

  const reorderTaskInState = useCallback(
    (dragIndex, hoverIndex, status) => {
      const dragTask = stateTasks[status].find(
        (task) => task.position === dragIndex
      );

      const tasksWithoutDragTask = sortByPosition(stateTasks[status]).filter(
        (t) => t.position !== dragTask.position
      );

      const reorderedTasks = [
        ...tasksWithoutDragTask.slice(0, hoverIndex - 1),
        dragTask,
        ...tasksWithoutDragTask.slice(hoverIndex - 1),
      ].map((task, idx) => ({
        ...task,
        position: idx + 1,
      }));

      setStateTasks({ ...stateTasks, [status]: reorderedTasks });
    },
    [stateTasks]
  );

  return Object.keys(STATUS_CLASSES).map((status, groupId) => (
    <StatusContainer
      key={groupId}
      groupId={groupId}
      targetStatus={status}
      onTaskReorder={onTaskReorder}
    >
      <Box sx={{ marginBottom: 2 }}>
        <Text variant="heading" sx={{ fontSize: 2 }}>
          {STATUS_LABELS[status]}
        </Text>
      </Box>
      <Preview>{generatePreview}</Preview>
      {stateTasks[status] && !!stateTasks[status].length ? (
        sortByPosition(stateTasks[status]).map((t) => (
          <Task
            key={t.id}
            task={t}
            onTaskReorder={onTaskReorder}
            onTaskRemove={onTaskRemove}
            onTaskChange={onTaskChange}
            onTaskReorderInState={reorderTaskInState}
            readOnly={readOnly}
          />
        ))
      ) : (
        <Text sx={{ paddingX: 3, fontStyle: "italic", opacity: "0.5" }}>
          none
        </Text>
      )}
    </StatusContainer>
  ));
};

TaskGroup.propTypes = {
  readOnly: PropTypes.bool,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTaskChange: PropTypes.func,
  onTaskRemove: PropTypes.func,
  onTaskReorder: PropTypes.func,
};

TaskGroup.defaultProps = {
  readOnly: true,
};

export default TaskGroup;
