/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const Aside = ({ children }) => (
  <aside
    sx={{
      display: [null, "none", "initial"],
      gridArea: "aside",
      mx: [4, 0, 0],
      paddingBottom: [9, 0, 0],
    }}
  >
    {children}
  </aside>
);

Aside.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Aside;
