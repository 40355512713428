import { TASK_CREATE_SUCCESS } from "./tasks";
import { remove } from "ramda";

export const TASK_COMPOSE = "TASK_COMPOSE";
export const INTEGRATION_TASK_EVENT = "INTEGRATION_TASK_EVENT";

const initialState = {};

export default function reducer(compose = initialState, action = {}) {
  const { task, projectId } = action;
  switch (action.type) {
    case TASK_COMPOSE:
      return {
        ...compose,
        [projectId]: {
          ...compose[projectId],
          ...task,
        },
      };
    case TASK_CREATE_SUCCESS:
      return {
        ...compose,
        [projectId]: {
          title: "",
          description: "",
          events: [],
        },
      };
    case INTEGRATION_TASK_EVENT: {
      const { event } = action;

      const task = compose[projectId];

      const hasEvent = (task.events || []).find((e) => e.id === event.id);

      let updatedEvents = [];
      if (hasEvent) {
        const eventIndex = task.events.indexOf(event);
        updatedEvents = remove(eventIndex, 1, task.events);
      } else {
        updatedEvents = [...(task.events || []), event].sort();
      }

      const title =
        task.events && !task.events.length && !task.title
          ? event.title
          : task.title;

      const newCompose = {
        ...compose,
        [projectId]: {
          ...task,
          title,
          events: updatedEvents,
        },
      };

      return newCompose;
    }
    default:
      return compose;
  }
}

export function integrationTaskEvent(event, projectId = "") {
  return {
    type: INTEGRATION_TASK_EVENT,
    event,
    projectId,
  };
}

export function composeTask(task, projectId = "") {
  return {
    type: TASK_COMPOSE,
    task,
    projectId,
  };
}
