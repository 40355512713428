/** @jsx jsx */
import { jsx, Heading } from "theme-ui";
import PropTypes from "prop-types";

const Header = ({ text, action }) => (
  <header sx={css.header}>
    <Heading
      as="h1"
      sx={{
        fontSize: 3,
      }}
    >
      {text}
    </Heading>
    {action && <span>{action}</span>}
  </header>
);

const css = {
  header: {
    alignItems: "center",
    borderBottom: "1px solid",
    borderColor: "dark.2",
    display: "flex",
    justifyContent: "space-between",
    paddingX: [4, 0, 0],
    gridArea: "header",
  },
};

Header.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.node,
};

export default Header;
