import isEmptyOrNil from "utils/isEmptyOrNil";
import { normalize } from "normalizr";
import { SUCCESS_STATUS_CODE } from "constants/api";

const ERROR_STATUS_BREAK = 300;

const methodMap = {
  del: "DELETE",
  get: "GET",
  patch: "PATCH",
  post: "POST",
  put: "PUT",
};

function parseResponse(response) {
  const type = response.headers.get("content-type") || "";
  const res = ~type.indexOf("application/json")
    ? response.json()
    : response.text();
  return res.then((body) => {
    response.parsedBody = body;
    return response;
  });
}

function checkHttpStatus(response) {
  if (
    response.status >= SUCCESS_STATUS_CODE &&
    response.status < ERROR_STATUS_BREAK
  ) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = parseResponse(response);
  error.status = response.status;
  throw error;
}

function parseSchema(schema) {
  return (response) => {
    if (typeof schema === "undefined") {
      return response;
    }

    response.parsedBody = normalize(response.parsedBody, schema);
    return response;
  };
}

export const queryParams = function (query = {}) {
  return Object.keys(query)
    .reduce((acc, curr) => {
      if (!query[curr]) {
        return acc;
      }
      return acc.concat(
        `${encodeURIComponent(curr)}=${encodeURIComponent(query[curr])}`
      );
    }, [])
    .join("&")
    .replace(/%20/g, "+");
};

export default class ApiClient {
  constructor({ token, schema }) {
    ["get", "post", "put", "patch", "del"].forEach((method) => {
      this[method] = (path, options) => {
        const opts = Object.assign(
          {
            method: methodMap[method],
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
          options
        );

        if (token) {
          opts.headers.Authorization = `Bearer ${token}`;
        }
        return fetch(
          this.formatQueryParams(this.formatUrl(path), opts.query),
          opts
        )
          .then(checkHttpStatus)
          .then(parseResponse)
          .then(parseSchema(schema));
      };
    });
  }

  formatQueryParams(url, query) {
    const queryString = queryParams(query);
    if (isEmptyOrNil(queryString)) {
      return url;
    }
    return `${url}${~url.indexOf("?") ? "&" : "?"}${queryString}`;
  }

  /* This was originally a standalone function outside of this class, but babel kept breaking, and this fixes it  */
  formatUrl(path) {
    const adjustedPath = path[0] !== "/" ? `/${path}` : path;
    return process.env.REACT_APP_API_SERVER + adjustedPath;
  }
}
