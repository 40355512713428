import { createSlice } from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";

const headerSlice = createSlice({
  name: "header",
  initialState: {
    title: "",
    details: "", // TODO: deprecate this as details are no longer used
  },
  reducers: {
    updateTitle(state, action) {
      state.title = action.payload;
    },
    updateDetails(state, action) {
      state.details = action.payload;
    },
  },
  extraReducers: {
    [LOCATION_CHANGE]: (state, action) => {
      state.title = "";
    },
  },
});

export const { updateTitle, updateDetails } = headerSlice.actions;

export default headerSlice.reducer;
