/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx, Text } from "theme-ui";
import { ReactComponent as Success } from "images/success.svg";
import { ReactComponent as Error } from "images/error.svg";
import { ReactComponent as Info } from "images/info.svg";

import { toast } from "react-toastify";

const notificationType = {
  info: {
    title: "Something looks fishy",
    icon: <Info />,
  },
  success: {
    title: "Success",
    icon: <Success />,
  },
  error: {
    title: "Uh oh!",
    icon: <Error />,
  },
};

const Notification = ({ message, type }) => {
  if (!type || !Object.keys(notificationType).find((key) => key === type))
    return null;

  return (
    <div>
      <div sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
        {notificationType[type].icon}
        <span
          sx={{
            fontWeight: "heading",
            color: "dark.0",
            marginLeft: 2,
          }}
        >
          {notificationType[type].title}
        </span>
      </div>
      <Text>{message}</Text>
    </div>
  );
};

Notification.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(["success", "error", "info"]).isRequired,
};

export const success = (message) => {
  toast(<Notification message={message} type="success" />);
};

export const error = (message) => {
  toast(<Notification message={message} type="error" />);
};

export const info = (message) => {
  toast(<Notification message={message} type="info" />);
};

export default { success, error, info };
