import { darken, lighten, alpha } from "@theme-ui/color";

/**
 * text styles
 **/
const baseHeading = {
  fontFamily: "heading",
  fontWeight: "heading",
  lineHeight: "heading",
};

const textVariants = {
  // this defines the default <Text> style
  default: {
    color: "text",
    fontSize: 1,
    lineHeight: "body",
  },
  small: {
    fontSize: 0,
  },
  heading: {
    ...baseHeading,
    fontSize: 3,
  },
  subhead: {
    ...baseHeading,
    fontSize: 2,
  },
  navigation: {
    ...baseHeading,
    color: "light.0",
    fontSize: 0,
    textShadow: "0px 1px 1px rgba(49, 105, 252, 0.6)",
  },
};

/**
 * button styles
 **/
const baseBtn = {
  borderRadius: 1,
  fontFamily: "heading",
  fontSize: "body",
  fontWeight: "bold",
  height: [5, 6],
  px: 3,
  py: 0,
  transition: "all 250ms cubic-bezier(0.65, 0, 0.35, 1)",
  cursor: "pointer",
};

const buttonsVariants = {
  primary: {
    color: "light.1",
    bg: "primary",
    ...baseBtn,
    "&:hover": {
      bg: darken("primary", 0.08),
    },
  },
  secondary: {
    bg: "light.0",
    border: "1px solid",
    borderColor: "dark.2",
    borderRadius: 1,
    color: "primary",
    ...baseBtn,
    "&:hover": {
      bg: lighten("primary", 0.27),
    },
  },
  minimal: {
    ...baseBtn,
    paddingX: 2,
    minWidth: [5, 6],
    bg: "inherit",
    "&:hover": {
      bg: alpha("primary", 0.1),
    },
  },
  text: {
    color: "dark.1",
    bg: "light.0",
    opacity: 0.6,
    paddingX: 0,
    paddingY: 0,
  },
  navigation: {
    ...baseBtn,
    display: "flex",
    fontSize: 0,
    alignItems: "center",
    color: "light.0",
    bg: "primary",
    textAlign: "left",
    textShadow: "0px 1px 1px rgba(49, 105, 252, 0.6)",
    paddingX: 3,
    "&:hover": {
      bg: darken("primary", 0.08),
    },
  },
  calendar: {
    ...baseBtn,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    border: "none",
    backgroundColor: "inherit",
    "&:hover": {
      bg: alpha("primary", 0.1),
    },
    borderRadius: 2,
    height: 7,
    width: 7,
  },
  github: {
    ...baseBtn,
    bg: "dark.0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      bg: darken("dark.0", 0.04),
    },
    "&:hover path": {
      transition: "all 250ms cubic-bezier(0.65, 0, 0.35, 1)",
      fill: "purple",
    },
  },
  dangerous: {
    ...baseBtn,
    paddingX: 2,
    minWidth: [5, 6],
    color: "red",
    bg: "inherit",
    "&:hover": {
      bg: alpha("red", 0.1),
    },
    "&:focus": {
      outline: "1px solid",
      outlineColor: "red",
    },
  },
  menu: {
    color: "light.0",
    width: 6,
    position: "absolute",
    height: 7,
    zIndex: 900,
  },
};

/**
 * Inputs
 **/
const inputVariants = {
  composer: {
    input: {
      background: "transparent",
      border: "none",
      fontSize: 1,
      paddingY: 3,
      "&:focus": {
        outline: "none",
      },
      "&:placeholder": {
        color: "placeholder",
      },
    },
    textarea: {
      background: "transparent",
      border: "none",
      color: "dark.1",
      fontFamily: "body",
      fontSize: 0,
      paddingY: 2,
      width: "100%",
      "&:focus": {
        outline: "none",
      },
      "&:placeholder": {
        color: "placeholder",
      },
    },
  },
};

/**
 * core theme
 **/
export default {
  fonts: {
    body: "'Inter', system-ui, sans-serif",
    heading: "inherit",
  },
  fontSizes: [
    "calc(12px + (14 - 12) * ((100vw - 400px) / (1800 - 400)))",
    "calc(15px + (18 - 15) * ((100vw - 400px) / (1800 - 400)))",
    "calc(19px + (22 - 19) * ((100vw - 400px) / (1800 - 400)))",
    "calc(24px + (28 - 24) * ((100vw - 400px) / (1800 - 400)))",
  ],
  fontWeights: { body: 400, bold: 500, heading: 700 },
  lineHeights: {
    body: 1.4,
    heading: 1.2,
  },
  colors: {
    text: "#3D414B",
    background: "#F7F8FD",
    primary: "#5F8BFC",
    secondary: "#3C71FB",
    dark: ["#3D414B", "#6F737F", "#C9CBCF"],
    light: ["#ffffff", "#F7F8FD", "#EFF1FB"],
    blue: "#5f8bfc",
    red: "#FC5F5F",
    cyan: "#49D1DF",
    green: "#83E036",
    gold: "#F7BF57",
    paper: "#FFFAF0",
    purple: "#BB6BD9",
    placeholder: `${alpha("dark.1", 0.5)}`,
    swatches: [
      "#5fcdfc",
      "#e56fc4",
      "#fba676",
      "#bf6fe5",
      "#4682b4",
      "#6b8e23",
      "#6ce2a2",
      "#cd5c5c",
      "#db7093",
      "#ff8c00",
      "#5f9ea0",
      "#9370db",
      "#3cb371",
      "#20b2aa",
      "#1e90ff",
      "#2f4f4f",
      "#ff7f50",
    ],
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 64, 128],
  sizes: [4, 8, 16, 24, 32, 40, 48, 64, 128, 256, 512, 640],
  breakpoints: ["420px", "769px", "1200px"],
  styles: {
    root: {
      fontFamily: "body",
    },
  },
  shadows: {
    card: `0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 1px 2px rgba(0, 0, 0, 0.02),
    0px 1px 4px rgba(0, 0, 0, 0.02),
    0px 2px 8px rgba(0, 0, 0, 0.02),
    0px 6px 20px rgba(0, 0, 0, 0.03)`,
  },
  text: textVariants,
  buttons: buttonsVariants,
  forms: {
    composer: inputVariants.composer.input,
    textarea: inputVariants.composer.textarea,
  },
  images: {
    avatar: {
      width: 5,
      height: 5,
    },
  },
  radii: [0, 1, 4],
};
