/** @jsx jsx */
import { jsx, Text, Flex } from "theme-ui";
import PropTypes from "prop-types";

const BlockLetter = ({ size = 6, letter, color = "dark.2", className }) => (
  <Flex
    className={className}
    sx={{
      height: size,
      backgroundColor: color,
      borderRadius: 1,
      flex: 0,
      flexBasis: size,
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Text
      variant="subhead"
      color="white"
      sx={{
        opacity: 0.9,
        mixBlendMode: "screen",
        fontSize: "inherit",
      }}
    >
      {letter.toUpperCase()}
    </Text>
  </Flex>
);

BlockLetter.propTypes = {
  letter: PropTypes.string.isRequired,
  color: PropTypes.string,
};
export default BlockLetter;
