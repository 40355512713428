/** @jsx jsx */
import { jsx, Box, Flex, Text } from "theme-ui";
import PropTypes from "prop-types";
import teammateIcon from "images/teammate-icon.png";
import greenCheckIcon from "images/greencheck.png";
import grayCheckIcon from "images/graycheck.png";
import BlockLetter from "components/BlockLetter";
import getProjectColor from "utils/colors";

const TeammateList = ({ composers, project, onClick }) => {
  const projectName = project.name;
  const projectColor = getProjectColor(project.id);

  return (
    <Box>
      <Flex sx={css.projectName}>
        {projectName && (
          <BlockLetter
            color={projectColor}
            size={3}
            sx={{ marginRight: 2 }}
            letter={projectName.charAt(0)}
          />
        )}
        <Text>{projectName}</Text>
      </Flex>
      <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
        <img sx={{ marginRight: 2 }} src={teammateIcon} alt="teammate icon" />
        <Text
          variant="subhead"
          onClick={onClick(null)}
          sx={{ cursor: "pointer" }}
        >
          Teammates
        </Text>
      </Flex>
      <Box sx={{ marginTop: 4 }}>
        {composers.map(({ id, name, completed }) =>
          completed ? (
            <Flex key={id} sx={sx.row}>
              <Text
                onClick={onClick(id)}
                sx={{ fontWeight: "heading", cursor: "pointer" }}
              >
                {name}
              </Text>
              <img src={greenCheckIcon} alt="completed standup" />
            </Flex>
          ) : (
            <Flex key={id} sx={sx.row}>
              <Text sx={{ fontWeight: "body" }}>{name}</Text>
              <img src={grayCheckIcon} alt="has not completed standup" />
            </Flex>
          )
        )}
      </Box>
    </Box>
  );
};

const css = {
  projectName: { flexDirection: "row", alignItems: "center", mb: 3 },
};

const sx = {
  row: {
    marginTop: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

TeammateList.propTypes = {
  composers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TeammateList;
