import * as React from "react";
import { useLayoutEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

function useAutoHeightAnimation(deps) {
  const controls = useAnimation();
  const ref = useRef(null);
  const height = useRef(null);

  useLayoutEffect(() => {
    ref.current.style.height = "auto";
    const newHeight = ref.current.offsetHeight;

    if (height.current !== null) {
      controls.set({ height: height.current });
      controls.start({ height: newHeight });
    }

    height.current = newHeight;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, controls, ...deps]);

  return [controls, ref];
}

export default function AnimatePresence({ show, children, className }) {
  const [controls, ref] = useAutoHeightAnimation([show, children]);

  return (
    <motion.div
      className={className}
      style={{ overflow: "hidden" }}
      animate={controls}
      ref={ref}
    >
      {show && children}
    </motion.div>
  );
}
