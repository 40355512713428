/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import moment from "moment";
import { dateType } from "constants/types";

const FormattedDate = ({ date, className }) => {
  const dateText = moment(date).format("dddd, MMMM");
  const dateNumber = moment(date)
    .format("Do")
    .match(/[a-z]+|\d+/g);

  return (
    <Text variant="heading" className={className}>
      {dateText} {dateNumber[0]}
      <sup>{dateNumber[1]}</sup>
    </Text>
  );
};

FormattedDate.propTypes = {
  date: dateType.isRequired,
};

export default FormattedDate;
