/** @jsx jsx */
import { jsx, Button } from "theme-ui";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { ReactComponent as Settings } from "images/settings.svg";
import BlockLetter from "components/BlockLetter";
import { restoreProjectById } from "redux/modules/projects";

export default function ProjectRow({
  name,
  when,
  to,
  edit,
  color,
  id,
  isArchived,
}) {
  const dispatch = useDispatch();

  const handleOnClickRestore = () => {
    dispatch(restoreProjectById(id));
  };

  return (
    <div sx={css.bg}>
      <BlockLetter color={color} letter={name.charAt(0)} />
      {!isArchived ? (
        <Fragment>
          <Link to={to} sx={css.link}>
            <span sx={{ fontWeight: "heading" }}>{name}&nbsp;</span>
            <span
              sx={{ display: ["none", "inline-block", null], color: "dark.1" }}
            >
              {when}
            </span>
          </Link>
          <Link to={edit}>
            <div sx={css.button}>
              <Button
                variant="minimal"
                sx={{
                  "&:hover path": {
                    fill: "primary",
                  },
                }}
              >
                <Settings />
              </Button>
            </div>
          </Link>
        </Fragment>
      ) : (
        <Fragment>
          <div sx={css.link}>
            <span sx={{ fontWeight: "heading" }}>{name}&nbsp;</span>
            <span
              sx={{ display: ["none", "inline-block", null], color: "dark.1" }}
            >
              {when}
            </span>
          </div>
          <Button
            sx={css.restoreButton}
            variant="minimal"
            onClick={handleOnClickRestore}
          >
            Restore
          </Button>
        </Fragment>
      )}
    </div>
  );
}

const css = {
  bg: {
    height: 7,
    display: "flex",
    padding: 2,
    alignItems: "center",
    position: "relative",
    borderBottom: "1px solid",
    borderColor: "dark.2",
    "&:hover, &:focus-within": {
      bg: "light.2",
    },
    "& svg": {
      opacity: [1, 1, 0], // no hover on mobile and tablet
    },
    "&:hover svg": {
      opacity: 1,
    },
    "&:hover > button": {
      opacity: 1,
    },
  },
  link: {
    mx: 3,
    position: "static",
    cursor: "pointer",
    fontSize: 1,
    flex: "1 1 66.6%",
    alignSelf: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "inherit",
    textDecoration: "none",
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
    },
    "&:focus": {
      outline: "none",
    },
    "&:visited": {
      color: "inherit",
    },
  },
  button: {
    position: "relative",
    zIndex: 1,
    marginLeft: "auto",
  },
  restoreButton: {
    color: "dark.1",
    cursor: "pointer",
    zIndex: 1,
    opacity: [1, 1, 0], // no hover on mobile and tablet
    "&:hover": {
      color: "primary",
    },
  },
};

ProjectRow.propTypes = {
  name: PropTypes.string.isRequired,
  when: PropTypes.string.isRequired,
  to: PropTypes.string,
  edit: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.number,
  isArchived: PropTypes.bool,
};
