import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";

export default class Blur extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.addEventListener("mousedown", this.handleWindowClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleWindowClick);
  }

  handleWindowClick = () => {
    const { onBlur } = this.props;
    if (!this.mouseIsDownOnComponent) {
      onBlur();
    }
  };

  handleMouseDown = () => {
    this.mouseIsDownOnComponent = true;
  };

  handleMouseUp = () => {
    this.mouseIsDownOnComponent = false;
  };

  render() {
    const { children, className } = this.props;
    return (
      <div
        role="button"
        tabIndex={0}
        className={classNames("Blur", className)}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
      >
        {children}
      </div>
    );
  }
}
