/** @jsx jsx */
import isEmptyOrNil from "utils/isEmptyOrNil";
import { jsx, Flex, Box, Text, Spinner } from "theme-ui";
import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";
import { useParams } from "react-router-dom";

import TaskGroup from "components/TaskGroup";
import Composer from "components/Composer";
import FormattedDate from "components/FormattedDate";
import SelectView from "components/SelectView";
import ProjectVitals from "components/ProjectVitals";
import Main from "components/Main";
import Aside from "components/Aside";

import { buildProjectUrl } from "utils/projectUrl";
import { SERVER_DATE_FORMAT } from "constants/api";
import { composeTask, integrationTaskEvent } from "redux/modules/compose";

import { getStandupForUser } from "redux/modules/standups";

import {
  createTask,
  updateTaskById,
  removeTaskById,
  reorderTask,
} from "redux/modules/tasks";
import { getIntegrations } from "redux/modules/integrations";
import { usePrevious } from "helpers/hooks";
import { getPrevStandup } from "utils/days";

import {
  selectProfileId,
  selectComposeDraft,
  selectStandupTasks,
  selectIntegrationEvents,
  selectCurrentUserIsComposer,
  selectStandupsLoading,
  selectAdjustedDays,
} from "redux/selectors";

const Standup = ({
  date,
  profileId,
  taskDraft = {},
  tasks = {},
  project,
  currentUserIsComposer,
  isLoading,
}) => {
  const [stateProject, setStateProject] = useState(project);
  const projectId = project.id;
  const prevDate = usePrevious(date);
  const dispatch = useDispatch();
  const { projectUuid } = useParams();

  const projectDays = useSelector((store) =>
    selectAdjustedDays({ store, projectUuid })
  );

  const prevStandupDate = getPrevStandup(projectDays, date);

  const events = useSelector((store) =>
    selectIntegrationEvents({
      store,
      projectUuid,
      date: moment(prevStandupDate).format(SERVER_DATE_FORMAT),
    })
  );

  useEffect(() => {
    if (
      (!isEmptyOrNil(project) && projectId !== stateProject.id) ||
      (prevDate && date !== prevDate)
    ) {
      dispatch(getIntegrations(projectId, prevStandupDate));
      dispatch(getStandupForUser(projectId, profileId, date));
      setStateProject(project);
    }
  }, [
    project,
    date,
    prevDate,
    projectId,
    stateProject,
    profileId,
    dispatch,
    prevStandupDate,
  ]);

  const projectUrl = buildProjectUrl(project);

  const handleTaskCreate = (task) => {
    return dispatch(createTask(task, projectId, profileId, date));
  };

  const handleTaskCompose = (change = {}) => {
    dispatch(composeTask(change, projectId));
  };

  const handleTaskChange = (id, change) => {
    return dispatch(updateTaskById(id, { ...change }));
  };

  const handleTaskRemove = (id) => {
    dispatch(removeTaskById(id, projectId, date));
  };

  const handleTaskReorder = (newPosition, task) => {
    dispatch(reorderTask(newPosition, task));
  };

  const handleSelectIntegrationEvent = (event) => {
    dispatch(integrationTaskEvent(event, projectId));
  };

  return (
    <React.Fragment>
      <Main>
        <Box mx={[4, 0, 0]}>
          <Flex sx={{ justifyContent: "space-between", marginBottom: 6 }}>
            <FormattedDate sx={{ flex: 1 }} date={date} />
            <SelectView
              projectUrl={projectUrl}
              date={date}
              selectedView="single"
              isComposer={currentUserIsComposer}
            />
          </Flex>
          {currentUserIsComposer && (
            <Composer
              draft={taskDraft}
              onChange={handleTaskCompose}
              onCreate={handleTaskCreate}
              events={events}
              onSelectIntegrationEvent={handleSelectIntegrationEvent}
            />
          )}
          {isEmptyOrNil(tasks) && !isLoading && (
            <Box sx={{ marginBottom: 4, marginTop: 2 }}>
              <Text>You don't have any standup items yet.</Text>
            </Box>
          )}
          {isLoading && (
            <Flex sx={{ justifyContent: "center", marginTop: 4 }}>
              <Spinner />
            </Flex>
          )}

          {!isLoading && (
            <TaskGroup
              className="Standup-tasks"
              tasks={tasks}
              readOnly={false}
              onTaskReorder={handleTaskReorder}
              onTaskChange={handleTaskChange}
              onTaskRemove={handleTaskRemove}
            />
          )}
        </Box>
      </Main>
      {project && (
        <Aside>
          <ProjectVitals project={project} />
        </Aside>
      )}
    </React.Fragment>
  );
};

Standup.propTypes = {
  date: PropTypes.string,
  profileId: PropTypes.number,
  project: PropTypes.object,
  taskDraft: PropTypes.object,
  tasks: PropTypes.arrayOf(PropTypes.object),
  currentUserIsComposer: PropTypes.bool,
};

export default connect(
  (store, props) => {
    const {
      location: { search },
      match: {
        params: { projectUuid },
      },
    } = props;

    const query = parse(search);
    const date = moment(query.date).format(SERVER_DATE_FORMAT);

    return {
      profileId: selectProfileId({ store }),
      date,
      taskDraft: selectComposeDraft({ store, projectUuid }),
      tasks: selectStandupTasks({ store, projectUuid, date }),
      currentUserIsComposer: selectCurrentUserIsComposer({
        store,
        projectUuid,
      }),
      isLoading: selectStandupsLoading({ store }),
    };
  },
  () => ({})
)(Standup);
