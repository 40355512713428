import { API_VERSION } from "constants/api";
import { success, error } from "utils/notifications";

export const INVITE_USER_LOAD = "INVITE_USER_LOAD";
export const INVITE_USER_LOAD_SUCCESS = "INVITE_USER_LOAD_SUCCESS";
export const INVITE_USER_LOAD_FAILURE = "INVITE_USER_LOAD_FAILURE";

const initialState = {};

export default function reducer(invite = initialState, action = {}) {
  switch (action.type) {
    case INVITE_USER_LOAD_FAILURE:
      error("There was a problem sending the invite");
      return invite;
    case INVITE_USER_LOAD_SUCCESS:
      success("Invite was sent");
      return invite;
    default:
      return invite;
  }
}

export function inviteUser(projectId = 0, email = "", composer = true) {
  return {
    types: [
      INVITE_USER_LOAD,
      INVITE_USER_LOAD_SUCCESS,
      INVITE_USER_LOAD_FAILURE,
    ],
    request: (api) =>
      api.post(`/${API_VERSION}/projects/${projectId}/invites`, {
        body: JSON.stringify({
          email,
          is_composer: composer,
        }),
      }),
  };
}
