/** @jsx jsx */
import { jsx, Select, Container } from "theme-ui";
import PropTypes from "prop-types";

const Dropdown = ({ options, value, onSelect, className }) => {
  const handleSelect = ({ target: { value } }) => {
    onSelect(value);
  };

  return (
    <Container className={className}>
      <Select sx={css.select} onChange={handleSelect} value={value}>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </Container>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func.isRequired,
};

const css = {
  select: {
    fontSize: 1,
    backgroundColor: "white",
    height: 6,
    paddingX: "12px",
    border: "solid 1px #d7dbe5",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
};

export default Dropdown;
