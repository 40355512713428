/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import PropTypes from "prop-types";
import { ReactComponent as Me } from "images/me.svg";
import { ReactComponent as Team } from "images/team.svg";
import { NavLink } from "react-router-dom";
import { dateType } from "constants/types";
import { alpha } from "@theme-ui/color";

const SelectView = ({ projectUrl, date, selectedView, isComposer }) => (
  <Flex sx={sx.container}>
    {isComposer && (
      <NavLink
        sx={{
          ...sx.link,
          mr: 2,
          fill: selectedView === "single" ? "primary" : "dark.1",
        }}
        to={{
          pathname: `${projectUrl}/me`,
          search: date ? `?date=${date}` : null,
        }}
      >
        <Me />
      </NavLink>
    )}
    <NavLink
      sx={{
        ...sx.link,
        fill: selectedView !== "single" ? "primary" : "dark.1",
      }}
      to={{
        pathname: `${projectUrl}`,
        search: date ? `?date=${date}` : null,
      }}
    >
      <Team />
    </NavLink>
  </Flex>
);

const sx = {
  container: {
    alignItems: ["center", "flex-end", "flex-end"],
  },
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    px: 1,
    "&:hover": {
      backgroundColor: alpha("primary", 0.1),
    },
  },
};

SelectView.propTypes = {
  projectUrl: PropTypes.string,
  date: dateType,
  selectedView: PropTypes.string,
  isComposer: PropTypes.bool,
};

export default SelectView;
