/* eslint-disable jsx-a11y/no-autofocus */
/** @jsx jsx */
import { useState, useRef } from "react";
import { jsx, Flex, Box, Input, Textarea, Button } from "theme-ui";
import { alpha } from "@theme-ui/color";
import PropTypes from "prop-types";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "utils/dragDropItems";
import Integration from "components/Integration";

const Task = ({
  task,
  onTaskRemove,
  onTaskChange,
  readOnly,
  onTaskReorderInState,
  onTaskReorder,
  isPreview = false,
}) => {
  const { title, notes, events, id } = task;

  const textarea = useRef();
  const ref = useRef(null);

  const [editing, setEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedDescription, setEditedDescription] = useState(notes.description);
  const [submitting, setSubmitting] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    canDrag: !readOnly && !editing,
    item: {
      type: ItemTypes.TASK,
      task,
      index: task.position,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.TASK,
    canDrop: (item) => item.task.status === task.status,
    hover(item, monitor) {
      if (!monitor.canDrop()) {
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = task.position;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onTaskReorderInState(dragIndex, hoverIndex, task.status);

      item.index = hoverIndex;
    },
    drop: (item) => {
      onTaskReorder(item.index, item.task);
    },
  });

  const handleDelete = () => {
    onTaskRemove(task.id, task.project_id);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleChangeTitle = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleChangeDescription = (e) => {
    setEditedDescription(e.target.value);
  };

  const handleCancel = () => {
    setEditedTitle(title);
    setEditedDescription(notes.description);
    setEditing(false);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    setSubmitting(true);
    onTaskChange(task.id, {
      title: editedTitle,
      notes: {
        ...notes,
        description: editedDescription,
      },
    }).then(() => {
      setEditing(false);
      setSubmitting(false);
    });
  };

  const handleFocusDescription = () => {
    const cursorPosition =
      editedDescription && !!editedDescription.length
        ? editedDescription.length
        : 0;
    textarea.current.setSelectionRange(cursorPosition, cursorPosition);
  };

  const formatDescription = (description, taskId) => {
    const lines = description.trim().split("\n");
    return lines.map((line, index) => <p key={`${index}_${taskId}`}>{line}</p>);
  };

  return (
    <Flex
      ref={drag(drop(ref))}
      sx={{
        opacity: isDragging ? "0" : "1",
        position: "relative",
        flexDirection: "column",
        borderBottom: isPreview ? "none" : "1px solid",
        borderColor: "dark.2",
        marginBottom: 4,
        "&:last-of-type": {
          marginBottom: "0px",
        },
      }}
    >
      <Flex sx={sx.listContainer}>
        {editing ? (
          <form onSubmit={(e) => handleUpdate(e)}>
            <Flex sx={{ flexDirection: "column" }}>
              <Input
                sx={sx.input}
                autoFocus
                type="text"
                value={editedTitle}
                onChange={handleChangeTitle}
              />
              <Flex sx={sx.descriptionContainer}>
                <Textarea
                  ref={textarea}
                  sx={sx.input}
                  value={editedDescription}
                  onChange={handleChangeDescription}
                  onFocus={handleFocusDescription}
                />
              </Flex>
              <Flex sx={sx.footer}>
                <ul sx={sx.footerActions}>
                  <li>
                    <Button
                      variant="text"
                      type="submit"
                      sx={sx.saveButton}
                      disabled={submitting}
                    >
                      Save
                    </Button>
                  </li>
                  <li sx={sx.footerItem}>
                    <Button
                      variant="text"
                      onClick={handleCancel}
                      sx={sx.saveButton}
                      disabled={submitting}
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Flex>
            </Flex>
          </form>
        ) : (
          <Flex sx={{ flexDirection: "column" }}>
            <span sx={{ fontSize: 2, mb: 3 }}>{title}</span>
            {notes.description && (
              <Flex sx={sx.descriptionContainer}>
                <Box sx={sx.description}>
                  {formatDescription(notes.description, id)}
                </Box>
              </Flex>
            )}
            {!readOnly && (
              <Flex sx={sx.footer}>
                <ul sx={sx.footerActions}>
                  <li>
                    <Button
                      variant="text"
                      sx={sx.editButton}
                      onClick={handleEdit}
                    >
                      Edit
                    </Button>
                  </li>
                  <li sx={sx.footerItem}>
                    <Button
                      variant="text"
                      sx={sx.deleteButton}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </li>
                </ul>
              </Flex>
            )}
          </Flex>
        )}
        {events &&
          !!events.length &&
          events.map((e) => <Integration key={e.id} event={e} />)}
      </Flex>
    </Flex>
  );
};

const sx = {
  listContainer: {
    flexDirection: "column",
    px: 3,
    minHeight: 7,
    "&:hover": {
      ".action-icon": {
        display: "flex",
      },
      ".action-date": {
        display: "none",
      },
    },
  },
  descriptionContainer: {
    marginBottom: 3,
  },
  description: {
    color: "dark.0",
    opacity: 0.6,
    "& p": {
      margin: 0,
    },
  },
  input: {
    marginBottom: 2,
    borderWidth: 1,
    borderColor: alpha("primary", 0.5),
    borderStyle: "solid",
    borderRadius: 1,
    "&:focus": {
      borderColor: "primary",
      backgroundColor: "white",
    },
  },
  footer: {
    marginBottom: 3,
  },
  footerActions: {
    display: "flex",
    flexDirection: "row",
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  footerItem: {
    ":before": {
      display: "inline-block",
      content: "''",
      borderRadius: "0.375rem",
      height: "0.45rem",
      width: "0.45rem",
      mx: "0.5rem",
      mb: "0.15rem",
      backgroundColor: "dark.1",
    },
  },
  editButton: {
    transition: "0.3s",
    ":hover": {
      color: "primary",
      opacity: 1,
    },
    cursor: "pointer",
  },
  saveButton: {
    color: "primary",
    fontWeight: "bold",
    transition: "0.3s",
    ":hover": {
      color: "primary",
      opacity: 1,
    },
    cursor: "pointer",
  },
  deleteButton: {
    position: "relative",
    transition: "0.3s",
    ":hover": {
      color: "red",
      opacity: 1,
    },
    cursor: "pointer",
  },
};

Task.propTypes = {
  task: PropTypes.shape({}),
  onTaskRemove: PropTypes.func,
  onTaskChange: PropTypes.func,
  readOnly: PropTypes.bool,
  isPreview: PropTypes.bool,
};

export default Task;
