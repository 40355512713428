/** @jsx jsx */
import { jsx } from "theme-ui";
import { alpha } from "@theme-ui/color";
import PropTypes from "prop-types";

const FancyInput = ({
  className,
  value,
  placeholder,
  onChange,
  label,
  type = "text",
  ...rest
}) => (
  <div className={className} sx={css.wrapper}>
    <label sx={css.label} htmlFor={label}>
      {label}
    </label>
    <input
      autoComplete="off"
      id={label}
      sx={css.fancyInput}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      {...rest}
    />
  </div>
);

FancyInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

const css = {
  wrapper: {
    position: "relative",
    outline: "1px solid",
    outlineColor: alpha("blue", 0.4),
    backgroundColor: "light.0",
    width: "100%",
    height: 6,
    display: "flex",
    flexDirection: "column",
    paddingY: 1,
    paddingX: 3,
    borderRadius: 1,

    "&:focus-within": {
      outlineColor: "blue",
    },
  },
  fancyInput: {
    border: "none",
    backgroundColor: "transparent",
    fontSize: [1, 0, 0],
    padding: 0,

    "&:focus": {
      outline: "none",
    },
  },
  label: {
    color: "secondary",
    width: "100%",
    fontSize: [1, 0, 0],
    fontWeight: "bold",
    marginBottom: "2px",
  },
};

export default FancyInput;
