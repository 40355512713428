/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "theme-ui";
import { alpha } from "@theme-ui/color";
import Integration from "components/Integration";
import { includes } from "ramda";

const IntegrationsList = ({
  selectedEvents,
  events,
  onSelectIntegrationEvent,
  className,
}) => (
  <div className={className} sx={{ width: "100%" }}>
    {events &&
      !!events.length &&
      events.map((event, i) => {
        const isSelected = includes(event, selectedEvents);
        return (
          <div
            role="button"
            tabIndex={0}
            key={event.id}
            sx={css.integrationWrapper}
            onClick={() => onSelectIntegrationEvent(event)}
            onKeyPress={() => onSelectIntegrationEvent(event)}
          >
            <div sx={isSelected ? css.selectedIntegration : css.integration}>
              <Integration key={i} event={event} />
            </div>
          </div>
        );
      })}
  </div>
);

const css = {
  integrationWrapper: {
    display: "flex",
  },
  integration: {
    flex: "1",
    overflow: "hidden",
    cursor: "pointer",
  },
  selectedIntegration: {
    flex: "1",
    overflow: "hidden",
    bg: alpha("purple", 0.1),
    cursor: "pointer",
  },
};

IntegrationsList.propTypes = {
  selectedEvents: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
  onSelectIntegrationEvent: PropTypes.func.isRequired,
};

IntegrationsList.defaultProps = {
  selectedEvents: [],
};

export default IntegrationsList;
