/** @jsx jsx */
import React from "react";
import PropTypes from "prop-types";
import { jsx, Grid } from "theme-ui";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Sidebar from "components/Sidebar";
import {
  selectActiveProjectsList,
  selectProjectListLoading,
  selectActiveProject,
} from "redux/selectors";
import HamburgerMenu from "components/HamburgerMenu";

function Page({ title = "Standuphub", children }) {
  const { projectUuid } = useParams();
  const projects = useSelector((store) => selectActiveProjectsList({ store }));
  const project = useSelector((store) =>
    selectActiveProject({ store, projectUuid })
  );
  const isLoading = useSelector((store) => selectProjectListLoading({ store }));

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <HamburgerMenu />
      <Grid
        gap={4}
        sx={{
          gridTemplateRows: (theme) => [
            `[page-start header-start nav-start sidebar-start]
            ${theme.sizes[8]}px
            [content-start header-end nav-end]
            1fr
            [page-end content-end aside-start sidebar-end]
            min-content
            [aside-end]`,
            `[page-start header-start nav-start sidebar-start]
            ${theme.sizes[8]}px
            [content-start header-end nav-end aside-start]
            1fr
            [page-end content-end aside-end sidebar-end]`,
          ],
          gridTemplateColumns: [
            `[header-start content-start nav-start aside-start]
                repeat(3, 1fr)
                [header-end content-end nav-end aside-end]`,
            `[sidebar-start]
                repeat(1, 1fr)
                [sidebar-end header-start content-start nav-start]
                repeat(5, 1fr)
                [header-end content-end nav-end]`,
            `[sidebar-start]
                repeat(2,1fr)
                [sidebar-end nav-start]
                1fr
                [content-start header-start]
                repeat(6, 1fr)
                [content-end header-end aside-start]
                repeat(3, 1fr)
                [nav-end aside-end]`,
          ],
          marginRight: [0, 4, 4],
        }}
      >
        <Sidebar
          sx={sx.sidebar}
          projects={projects}
          isLoading={isLoading}
          activeProject={project}
        />
        {children}
      </Grid>
    </React.Fragment>
  );
}

const sx = {
  sidebar: {
    gridArea: "sidebar",
    display: ["none", "flex", "flex"],
    height: ["auto", "100vh", "100vh"],
    position: ["static", "sticky", "sticky"],
    overflow: "auto",
    top: 0,
  },
};

Page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  projects: PropTypes.arrayOf(PropTypes.object),
};

export default Page;
