import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div>
    <h1>Dang, i could have sworn i put it right here. Oh well...</h1>
    <h2>Sorry, couldn't find that page you wanted.</h2>
    <Link to="/">Take me back home please</Link>
  </div>
);

export default NotFound;
