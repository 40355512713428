/** @jsx jsx */
import { useState, useEffect } from "react";
import { jsx, Flex, Box, Text, Button, Heading } from "theme-ui";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { routerActions } from "connected-react-router";
import { login, clearAuthMessage } from "redux/modules/auth";
import { Link, useLocation } from "react-router-dom";
import { parse } from "query-string";
import { ReactComponent as GithubIcon } from "images/github-logo.svg";
import { ReactComponent as EmailIcon } from "images/email.svg";
import FancyInput from "components/FancyInput";
import ShinyHappyPeopleDesktop from "images/shiny-happy-people-desktop.svg";
import ShinyHappyPeopleMobile from "images/shiny-happy-people-mobile.svg";
import { selectIsAuthenticated, selectAuthMessage } from "redux/selectors";

const Login = () => {
  const [email, updateEmail] = useState("");
  const [password, updatePassword] = useState("");
  const [emailOption, updateEmailOption] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const isAuthenticated = useSelector((store) =>
    selectIsAuthenticated({ store })
  );
  const authMessage = useSelector((store) => selectAuthMessage({ store }));

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(routerActions.replace("/"));
    }
  }, [isAuthenticated, dispatch]);

  const handleLogin = (e) => {
    e.preventDefault();

    const { next: redirectRoute } = parse(location.search);
    dispatch(login(email, password, redirectRoute));
  };

  const handleUpdateEmail = (value) => {
    updateEmail(value);
    if (authMessage.length) {
      dispatch(clearAuthMessage());
    }
  };

  const handleUpdatePassword = (value) => {
    updatePassword(value);
    if (authMessage.length) {
      dispatch(clearAuthMessage());
    }
  };

  return (
    <Flex
      color="light.0"
      bg="primary"
      sx={{
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>StandupHub | Login</title>
      </Helmet>
      <Box sx={css.bg}>
        <Box sx={{ maxWidth: ["100%", "50vw", "25vw"], mx: "auto" }}>
          <Heading variant="heading" sx={{ textAlign: "center", mb: 3 }}>
            Welcome to StandupHub
          </Heading>
          {emailOption ? (
            <Box sx={css.form}>
              <form onSubmit={handleLogin}>
                <FancyInput
                  label="Email"
                  type="email"
                  value={email}
                  onChange={({ target }) => handleUpdateEmail(target.value)}
                  placeholder="example@email.com"
                  required
                  sx={{
                    backgroundColor: "white",
                    border: "1px solid",
                    borderColor: "dark.2",
                    borderRadius: 1,
                    marginBottom: 3,
                  }}
                />
                <FancyInput
                  label="Password"
                  type="password"
                  value={password}
                  onChange={({ target }) => handleUpdatePassword(target.value)}
                  placeholder="Something super secure.."
                  required
                  sx={{
                    backgroundColor: "white",
                    border: "1px solid",
                    borderColor: "dark.2",
                    borderRadius: 1,
                    marginBottom: 3,
                  }}
                />

                <Text
                  variant="small"
                  sx={{
                    textAlign: "end",
                  }}
                >
                  <Link to="/forgot-password" sx={{ color: "light.0" }}>
                    Forgot Password?
                  </Link>
                </Text>
                <Button
                  variant="secondary"
                  sx={{ marginTop: 3, width: "100%" }}
                >
                  <Text>Log in</Text>
                </Button>
              </form>
              {authMessage && (
                <Text className="flash-error" sx={css.error}>
                  {authMessage}
                </Text>
              )}
              <Text variant="small" sx={{ textAlign: "center", my: 3 }}>
                <Link to="/signup" sx={{ color: "inherit" }}>
                  Don't have an account? Sign up
                </Link>
              </Text>
            </Box>
          ) : (
            <Box sx={css.form}>
              <Button
                as="a"
                variant="github"
                href={`${process.env.REACT_APP_API_SERVER}/auth/github?redirect_url=${window.location.href}`}
                sx={{ mb: 3 }}
              >
                <GithubIcon />
                <Text sx={{ mx: 2 }}>Login with Github</Text>
              </Button>

              <Button
                variant="secondary"
                onClick={() => updateEmailOption(true)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <EmailIcon />
                <Text sx={{ mx: 2 }}>Log in with Email</Text>
              </Button>
              <Text variant="small" sx={{ textAlign: "center", my: 3 }}>
                <Link to="/signup" sx={{ color: "inherit" }}>
                  Don't have an account? Sign up
                </Link>
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

const css = {
  bg: {
    width: "100%",
    mt: 9,
    mx: [4, 0, 0],
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 100%",
    backgroundImage: [
      `url(${ShinyHappyPeopleMobile})`,
      `url(${ShinyHappyPeopleDesktop})`,
      `url(${ShinyHappyPeopleDesktop})`,
    ],
  },
  error: {
    textAlign: "center",
    marginY: 3,
  },
  form: {
    backgroundColor: "primary",
    padding: 4,
    boxShadow: "10px 10px 8px rgba(0, 0, 0, 0.2)",
    border: "1px solid",
    borderColor: "light.2",
  },
};

export default Login;
