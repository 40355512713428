export const API_VERSION = "v1";
export const SUCCESS_STATUS_CODE = 200;

export const CLIENT_DATE_FORMAT = "YYYY-MM-DD";
export const SERVER_DATE_FORMAT = "YYYYMMDD";

export const STATUS_COMPLETE = "complete";
export const STATUS_IN_PROGRESS = "in-progress";
export const STATUS_BLOCKED = "blocked";

export const STATUSES = [STATUS_COMPLETE, STATUS_IN_PROGRESS, STATUS_BLOCKED];

export const STATUS_LABELS = {
  [STATUS_COMPLETE]: "Done",
  [STATUS_BLOCKED]: "Blockers",
  [STATUS_IN_PROGRESS]: "In Progress",
};

export const STATUS_CLASSES = {
  [STATUS_COMPLETE]: "complete",
  [STATUS_IN_PROGRESS]: "in-progress",
  [STATUS_BLOCKED]: "blocked",
};

export const TASK_DEFAULTS = {
  title: "",
  status: STATUS_IN_PROGRESS,
  notes: {},
};
