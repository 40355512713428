import moment from "moment";
import {
  API_VERSION,
  CLIENT_DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from "constants/api";
import { EventList } from "schemas";

export const EVENTS_LOAD = "EVENTS_LOAD";
export const EVENTS_LOAD_SUCCESS = "EVENTS_LOAD_SUCCESS";
export const EVENTS_LOAD_FAILURE = "EVENTS_LOAD_FAILURE";

const initialState = {};

export default function reducer(integrations = initialState, action = {}) {
  const { date, projectId, response } = action;

  switch (action.type) {
    case EVENTS_LOAD_SUCCESS: {
      const { result } = response;

      const eventIDs = [...new Set(result)];

      return {
        ...integrations,
        [date]: {
          ...integrations[date],
          [projectId]: eventIDs,
        },
      };
    }
    default:
      return integrations;
  }
}

export function getIntegrations(
  projectId = "",
  date = moment().format(CLIENT_DATE_FORMAT)
) {
  const serverDate = moment(date).format(SERVER_DATE_FORMAT);
  return {
    types: [EVENTS_LOAD, EVENTS_LOAD_SUCCESS, EVENTS_LOAD_FAILURE],
    request: (api) =>
      api.get(`/${API_VERSION}/events/${projectId}/${serverDate}`),
    schema: EventList,
    date: serverDate,
    projectId,
  };
}
