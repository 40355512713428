/** @jsx jsx */
import { jsx, Button } from "theme-ui";
import PropTypes from "prop-types";
import { ReactComponent as Caret } from "images/caret.svg";

const Arrow = ({ onClick, direction }) => (
  <Button variant="calendar" onClick={onClick}>
    <span
      sx={{
        transform: direction === "left" ? "rotate(270deg)" : "rotate(90deg)",
      }}
    >
      <Caret />
    </span>
  </Button>
);

Arrow.propTypes = {
  direction: PropTypes.oneOf(["left", "right"]),
};

export default Arrow;
