export const FORGOT_PASSWORD_LOAD = "FORGOT_PASSWORD_LOAD";
export const FORGOT_PASSWORD_LOAD_SUCCESS = "FORGOT_PASSWORD_LOAD_SUCCESS";
export const FORGOT_PASSWORD_LOAD_FAILURE = "FORGOT_PASSWORD_LOAD_FAILURE";

export const RESET_PASSWORD_LOAD = "RESET_PASSWORD_LOAD";
export const RESET_PASSWORD_LOAD_SUCCESS = "RESET_PASSWORD_LOAD_SUCCESS";
export const RESET_PASSWORD_LOAD_FAILURE = "RESET_PASSWORD_LOAD_FAILURE";

export function forgotPassword(email = "") {
  return {
    types: [
      FORGOT_PASSWORD_LOAD,
      FORGOT_PASSWORD_LOAD_SUCCESS,
      FORGOT_PASSWORD_LOAD_FAILURE,
    ],
    request: (api) =>
      api.post("/forgot-password", { body: JSON.stringify({ email }) }),
  };
}

export function resetPassword(token = "", password = "") {
  return {
    types: [
      RESET_PASSWORD_LOAD,
      RESET_PASSWORD_LOAD_SUCCESS,
      RESET_PASSWORD_LOAD_FAILURE,
    ],
    request: (api) =>
      api.post("/reset-password", {
        body: JSON.stringify({ token, password }),
      }),
  };
}
