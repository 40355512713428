import moment from "moment";
import { getDayStreak } from "./days";
import {
  convertToUserTimezone,
  CLIENT_TIME_TIMEZONE_FORMAT,
  getProjectTime,
} from "./times";

export const getDetails = (days, time, timezone) => {
  let detailsString = getDayStreak(days);

  if (detailsString.length > 0) {
    detailsString += " @ ";
  }
  detailsString += getProjectTime(time, timezone).format(
    CLIENT_TIME_TIMEZONE_FORMAT
  );

  return detailsString;
};

/**
 * returns a presentation ready string containing the next
 * standup date, relative to the current local time.
 */
export const getNextStandup = (days, time, timezone) => {
  const adjustedStandupTime = convertToUserTimezone(time, timezone);
  const currentDay = adjustedStandupTime.clone();
  const nextDay = getNextDay(days, adjustedStandupTime);
  if (!nextDay) return "";
  const nextTime = nextDay.format(CLIENT_TIME_TIMEZONE_FORMAT);
  if (currentDay.isSame(nextDay)) {
    return `Standup: Today @ ${nextTime}`;
  }
  if (currentDay.clone().add(1, "day").isSame(nextDay)) {
    return `Standup: Tomorrow @ ${nextTime}`;
  } else {
    return `Standup: ${nextDay.format("dddd")} @ ${nextTime}`;
  }
};

/**
 * calculates the next standup date, relative to the current local
 * time.
 * @param {number[]} days - the days of the week a standup occurs
 * @param {moment} adjustedStandupTime
 *    - moment representing the time of day the standup occurs,
 *      adjusted for the local timezone
 */
function getNextDay(daysArray = [], adjustedStandupTime) {
  const now = moment();
  const thisWeekMoment = adjustedStandupTime.clone().day(0);
  const thisWeek = daysArray.map((i) => thisWeekMoment.clone().day(i));
  const nextWeekMoment = thisWeekMoment.clone().add(1, "week");
  const nextWeek = daysArray.map((i) => nextWeekMoment.clone().day(i));

  const nextDay = [...thisWeek, ...nextWeek].find((d) => d.isSameOrAfter(now));

  return nextDay;
}
