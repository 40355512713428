/** @jsx jsx */
import { jsx } from "theme-ui";
import { CLIENT_DATE_FORMAT } from "constants/api";
import theme from "theme";
import DateCell from "./date-cell";

const Dates = ({ dates, projectDays, handleDateChange }) => (
  <div sx={css}>
    {dates.map((d) => (
      <DateCell
        date={d}
        key={d.format(CLIENT_DATE_FORMAT)}
        projectDays={projectDays}
        handleDateChange={handleDateChange}
      />
    ))}
  </div>
);

const css = {
  display: "flex",
  flex: "1 1 auto",
  alignItems: "stretch",
  flexDirection: "row",
  justifyContent: "space-evenly",
  [`@media screen and (max-width: ${theme.breakpoints[2]})`]: {
    [`& > :nth-of-type(1), & > :nth-of-type(9)`]: {
      display: "none",
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    [`& > :nth-of-type(2), & > :nth-of-type(8), & > :nth-of-type(3), & > :nth-of-type(7)`]: {
      display: "none",
    },
  },
};

export default Dates;
