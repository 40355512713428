/** @jsx jsx */
import { jsx } from "theme-ui";
import moment from "moment";
import { useSelector } from "react-redux";

import { CLIENT_DATE_FORMAT } from "constants/api";
import { nextValidDay, prevValidDay } from "utils/days";
import PropTypes from "prop-types";
import { dateType } from "constants/types";
import Arrow from "./arrow";
import { selectAdjustedDays } from "redux/selectors";
import Dates from "./dates";

const DateNavigation = ({ date, projectUuid, onDateChange }) => {
  const projectDays = useSelector((store) =>
    selectAdjustedDays({ store, projectUuid })
  );

  const handleDateIncrement = () => {
    const currentMoment = moment(date);
    const currentDay = currentMoment.day();
    const nextDay = nextValidDay(projectDays, currentDay);

    const addCount = Math.abs(currentDay - (nextDay + 7)) % 7 || 7;
    const newDate = currentMoment
      .add(addCount, "days")
      .format(CLIENT_DATE_FORMAT);

    onDateChange(newDate);
  };

  const handleDateDecrement = () => {
    const currentMoment = moment(date);
    const currentDay = currentMoment.day();
    const previousValidDay = prevValidDay(projectDays, currentDay);

    const subtractCount = (currentDay - (previousValidDay - 7)) % 7 || 7;
    const newDate = currentMoment
      .subtract(subtractCount, "days")
      .format(CLIENT_DATE_FORMAT);

    onDateChange(newDate);
  };

  const handleDateChange = (selection) => {
    const newDate = selection.format(CLIENT_DATE_FORMAT);
    return onDateChange(newDate);
  };

  const currentMoment = moment(date);
  const startMoment = currentMoment.subtract(4, "days");
  const dates = [...Array(9).keys()].map((v) =>
    startMoment.clone().add(v, "days")
  );

  return (
    <nav
      sx={{
        borderBottomStyle: "solid",
        borderBottomColor: "dark.2",
        borderBottomWidth: "thin",
        paddingBottom: 4,
        gridArea: "nav",
      }}
    >
      <div
        sx={{
          display: "flex",
          marginTop: 4,
        }}
      >
        <Arrow direction="left" onClick={handleDateDecrement} />
        <Dates
          handleDateChange={handleDateChange}
          dates={dates}
          projectDays={projectDays}
        />
        <Arrow direction="right" onClick={handleDateIncrement} />
      </div>
    </nav>
  );
};

DateNavigation.propTypes = {
  date: dateType,
  projectUuid: PropTypes.string,
  projectDays: PropTypes.object,
  onDateChange: PropTypes.func,
};

export default DateNavigation;
