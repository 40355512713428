/** @jsx jsx */
import { Link } from "react-router-dom";
import { jsx, Flex, Button, Text } from "theme-ui";
import { darken } from "@theme-ui/color";
import PropTypes from "prop-types";
import getProjectColor from "utils/colors";
import { ReactComponent as StandupHubLogo } from "images/standuphub-logo.svg";
import BlockLetter from "components/BlockLetter";

/** TODO: The api does not yet handle the generating of the "project color",
 * this color is used for the BlockLetter component, for now, we wont persist a
 * color but just generate a random hex code.
 */
const ProjectButton = ({ label, to, color, onClick, active }) => (
  <Button
    to={to}
    as={Link}
    onClick={onClick}
    sx={{ bg: active ? darken("primary", 0.08) : "primary" }}
    variant="navigation"
  >
    <BlockLetter color={color} letter={label.charAt(0)} size={3} />
    <span
      sx={{
        marginX: 2,
        width: "66.6%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {label}
    </span>
  </Button>
);

const Sidebar = ({
  projects,
  activeProject = {},
  isLoading,
  className,
  onClose,
}) => (
  <div className={className} sx={css.outer}>
    <Link to="/">
      <Flex sx={css.logoSection}>
        <StandupHubLogo sx={css.logo} />
      </Flex>
    </Link>
    <nav>
      {!isLoading && projects && projects.length > 0 && (
        <div sx={css.projectSection}>
          <div sx={{ paddingX: 2, marginBottom: 4 }}>
            <Text variant="navigation">My Projects</Text>
          </div>
          <Flex sx={{ flexDirection: "column" }}>
            {projects.map(({ id, uuid, slug, name }) => (
              <ProjectButton
                color={getProjectColor(id)}
                key={uuid}
                onClick={onClose}
                active={uuid === activeProject.uuid}
                to={`/projects/${slug}/${uuid}/me`}
                label={name}
              />
            ))}
          </Flex>
        </div>
      )}
      <Button
        sx={{ display: "flex", px: 3 }}
        as={Link}
        to="/"
        onClick={onClose}
        variant="navigation"
      >
        All Projects
      </Button>
    </nav>
    <Button
      sx={{ display: "flex", marginTop: "auto", px: 3 }}
      as={Link}
      to="/settings"
      onClick={onClose}
      variant="navigation"
    >
      Settings
    </Button>
  </div>
);

const css = {
  outer: {
    bg: "primary",
    flexDirection: "column",
  },
  projectSection: {
    borderBottom: "1px solid",
    borderColor: "secondary",
    paddingX: 2,
    paddingBottom: 4,
    marginBottom: 4,
  },
  logoSection: {
    paddingX: 3,
    paddingY: 4,
    marginBottom: 4,
    borderBottom: "1px solid",
    borderColor: "secondary",
    justifyContent: "center",
  },
  logo: {
    width: "100%",
    minWidth: "100px",
    maxWidth: "155px",
    "& path": {
      fill: "white",
    },
  },
};

Sidebar.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  activeProject: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
};

export default Sidebar;
