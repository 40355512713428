/** @jsx jsx */
import capitalize from "utils/capitalize";
import { jsx, Text, Flex } from "theme-ui";
import { alpha } from "@theme-ui/color";
import PropTypes from "prop-types";
import githubIcon from "images/github-logo.svg";
import { ReactComponent as ExternalLinkIcon } from "images/external-link-icon.svg";

const Integration = ({ event = {} }) => {
  const eventType = event.type.split(" ")[2];

  const formattedEventText =
    capitalize(event.action) +
    " " +
    eventType +
    " #" +
    event.id +
    ": " +
    event.title;

  return (
    <div sx={css.selected}>
      <Flex sx={css.event}>
        <img src={githubIcon} sx={css.github} alt="Github Icon for events" />
        <Text sx={css.label}>{formattedEventText}</Text>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={event.external_link}
          sx={css.link}
        >
          <div sx={css.externalLinkIcon}>
            <ExternalLinkIcon />
          </div>
        </a>
      </Flex>
    </div>
  );
};

const css = {
  github: {
    alignSelf: "center",
  },
  event: {
    padding: 2,
    width: "100%",
    justifyContent: "flex-start",
    fontSize: 0,
    alignItems: "center",
  },
  label: {
    paddingLeft: 2,
  },
  link: {
    justifySelf: "flex-end",
    marginLeft: "auto",
  },
  externalLinkIcon: {
    width: 2,
    "& g": { fill: "dark.1" },
    marginLeft: 3,
  },
  selected: {
    "&:hover": { bg: (theme) => alpha(theme.colors.purple, 0.1) },
  },
};

Integration.propTypes = {
  event: PropTypes.object.isRequired,
};

export default Integration;
