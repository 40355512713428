import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.lazy((value) => {
    if (value && !value.trim()) {
      return Yup.string().max(0, "*Enter valid name");
    } else {
      return Yup.string().required("*Project name is required");
    }
  }),
  meeting_url: Yup.lazy((value) => {
    if (value && !value.trim()) {
      return Yup.string().max(0, "*Enter valid URL");
    } else {
      return Yup.string().when("pin", {
        is: (value) => value && value.length > 0,
        then: Yup.string().required("*Required for Meeting PIN"),
        otherwise: Yup.string(),
      });
    }
  }),
  pin: Yup.lazy((value) => {
    if (value && !value.trim()) {
      return Yup.string().max(0, "*Enter valid PIN");
    } else {
      return Yup.string();
    }
  }),
  phone_number: Yup.lazy((value) => {
    if (value && !value.trim()) {
      return Yup.string().max(0, "*Enter valid phone number");
    } else {
      return Yup.string().when("access_code", {
        is: (value) => value && value.length > 0,
        then: Yup.string().required("*Required for Call In Code"),
        otherwise: Yup.string(),
      });
    }
  }),
  access_code: Yup.lazy((value) => {
    if (value && !value.trim()) {
      return Yup.string().max(0, "*Enter valid code");
    } else {
      return Yup.string();
    }
  }),
  time: Yup.number().min(0, "*Time is required"),
  timezone: Yup.lazy((value) => {
    switch (typeof value) {
      case "number":
        return Yup.number().positive("*Time is required");
      default:
        return Yup.string();
    }
  }),
  days: Yup.array().min(1, "*Please select at least 1 day"),
});

export default validationSchema;
