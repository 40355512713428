/* eslint-disable jsx-a11y/no-autofocus */
/** @jsx jsx */
import { useState, useRef } from "react";
import isEmptyOrNil from "utils/isEmptyOrNil";
import PropTypes from "prop-types";
import { jsx, Input, Button, Textarea } from "theme-ui";
import AnimatePresence from "components/AnimatePresence";
import { ReactComponent as PaperAirplane } from "images/paper-airplane.svg";

import { STATUS_IN_PROGRESS } from "constants/api";
import Toggles from "components/Composer/toggles";

import IntegrationsList from "components/IntegrationsList";

export default function Composer({
  draft = {},
  onChange,
  onCreate,
  events,
  onSelectIntegrationEvent,
}) {
  const [composing, setComposing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const inputRef = useRef();

  const title = draft.title || "";
  const description = draft.description || "";
  const status = draft.status || STATUS_IN_PROGRESS;

  const reset = () => {
    inputRef.current.focus();
  };

  const isComposing = () => {
    return !isEmptyOrNil(draft.title) || !isEmptyOrNil(draft.description);
  };

  const handleTitleChange = ({ target: { value } }) => {
    onChange({ ...draft, title: value });

    if (value) {
      setComposing(true);
    }
  };

  const handleDescriptionChange = ({ target: { value } }) => {
    onChange({ ...draft, description: value });
  };

  const handleStatusChange = (status) => {
    onChange({ ...draft, status });
  };

  const handleTaskCreate = (e) => {
    e.preventDefault();

    if (!isEmptyOrNil(draft.title) && !isSubmitting) {
      setIsSubmitting(true);
      setComposing(false);

      const events = draft.events || [];

      const newTask = {
        title: draft.title,
        status: draft.status || STATUS_IN_PROGRESS,
        notes: {
          description: draft.description,
        },
      };

      if (events && !!events.length) {
        newTask.events = events;
      }

      onCreate(newTask)
        .then(() => reset())
        .then(() => setIsSubmitting(false));
    }

    setComposing(isComposing());
  };

  const handleFocus = () => {
    setComposing(isComposing());
  };

  return (
    <div sx={{ position: "relative", height: 7, marginBottom: "19px" }}>
      <div sx={{ position: "absolute", width: "100%", zIndex: 1 }}>
        <form onBlur={handleFocus} onSubmit={(e) => handleTaskCreate(e)}>
          <div sx={{ backgroundColor: "white", ...css.column }}>
            <div
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Input
                variant="composer"
                ref={inputRef}
                autoFocus
                type="text"
                placeholder="Post a new status..."
                value={title}
                onChange={handleTitleChange}
                onFocus={handleFocus}
                onBlur={handleFocus}
              />
              <Button
                disabled={isSubmitting}
                variant="minimal"
                type="submit"
                sx={{ "&:hover path": { fill: "primary" } }}
                onClick={handleTaskCreate}
              >
                <PaperAirplane />
              </Button>
            </div>
            <AnimatePresence
              show={composing}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {events && !!events.length && (
                <IntegrationsList
                  sx={{ overflow: "auto", maxHeight: 10 }}
                  events={events}
                  selectedEvents={draft.events}
                  onSelectIntegrationEvent={onSelectIntegrationEvent}
                />
              )}
              <Textarea
                disabled={!composing}
                onChange={handleDescriptionChange}
                placeholder="Any notes to go with your status?…"
                value={description}
              />
              <div sx={{ paddingY: 2, position: "relative" }}>
                <Toggles
                  active={status}
                  hidden={!composing}
                  onChange={handleStatusChange}
                />
              </div>
            </AnimatePresence>
          </div>
        </form>
      </div>
    </div>
  );
}

const css = {
  bg: {
    bg: "white",
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    transformOrigin: "top center",
    boxShadow: "card",
  },
  column: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingX: 2,
    position: "relative",
    boxShadow: "card",
    transformOrigin: "top center",
  },
};

Composer.propTypes = {
  draft: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(PropTypes.object),
};
