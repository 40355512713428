import { getCurrentLocalTime } from "./times";
import moment from "moment";
import { uniq, last, dropWhile } from "ramda";

const DAYS_OF_WEEK = ["Su", "M", "T", "W", "Th", "F", "S"];

export function closestValidDay(daysArray = [], day = 0) {
  if (!daysArray.length) {
    return -1;
  }

  const formattedDays = uniq(daysArray.sort());
  if (formattedDays.includes(day)) {
    return day;
  }

  if (day > last(formattedDays)) {
    return formattedDays[0];
  }

  return dropWhile((x) => x < day, formattedDays)[0];
}

export function nextValidDay(daysArray = [], currentDay = 0) {
  if (!daysArray.length) {
    return -1;
  }

  const formattedDays = uniq(daysArray.sort());
  const matchingDay = formattedDays.indexOf(currentDay);

  if (~matchingDay) {
    return formattedDays[(matchingDay + 1) % formattedDays.length];
  }
  return closestValidDay(formattedDays, currentDay);
}

export function prevValidDay(daysArray = [], currentDay) {
  if (!daysArray.length) {
    return -1;
  }

  const formattedDays = uniq(daysArray.sort());
  const matchingDay = formattedDays.indexOf(currentDay);

  let prevIndex;
  if (~matchingDay) {
    prevIndex =
      (matchingDay + (formattedDays.length - 1)) % formattedDays.length;
  } else {
    const closestNextDay = closestValidDay(formattedDays, currentDay);
    const closestNextDayIndex = formattedDays.indexOf(closestNextDay);

    prevIndex =
      (closestNextDayIndex + (formattedDays.length - 1)) % formattedDays.length;
  }

  return formattedDays[prevIndex];
}

export function getDayStreak(daysArray = []) {
  const formattedDays = uniq(daysArray.sort());

  let streak = 0;
  let last = null;
  let daysString = "";

  formattedDays.forEach((d, i) => {
    if (daysString === "") {
      daysString += DAYS_OF_WEEK[d];
    } else if (last !== null && d - last === 1) {
      streak++;
      if (i === formattedDays.length - 1) {
        if (streak > 1) {
          daysString += `-${DAYS_OF_WEEK[d]}`;
        } else {
          daysString += `, ${DAYS_OF_WEEK[d]}`;
        }
      }
    } else {
      if (streak > 1) {
        daysString += `-${DAYS_OF_WEEK[last]}, ${DAYS_OF_WEEK[d]}`;
      } else if (streak === 1) {
        daysString += `, ${DAYS_OF_WEEK[last]}, ${DAYS_OF_WEEK[d]}`;
      } else {
        daysString += `, ${DAYS_OF_WEEK[d]}`;
      }
      streak = 0;
    }

    last = d;
  });

  return daysString;
}

/**
 * daysArray: list of day of week numbers (0-6)
 * projectTime: the time of day the standup is, in the project timezone
 *
 * returns an adjusted day number array for the local timezone
 **/
export function localizeDays(daysArray = [], projectTime) {
  return daysArray.map((d) =>
    getCurrentLocalTime(projectTime.clone().day(d)).day()
  );
}

/**
 * daysArray: list of day of week numbers (0-6)
 * date: the current date string: ex. 202000817
 *
 * returns: moment object referring to the last standup date.
 **/
export const getPrevStandup = (daysWeek, date) => {
  const currDayOfWeek = moment(date).day();
  const prevStandup = prevValidDay(daysWeek, currDayOfWeek);

  const dayDiff = prevStandup > currDayOfWeek ? prevStandup - 7 : prevStandup;

  return moment(date).day(dayDiff);
};
