import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { routerActions } from "connected-react-router";
import { selectIsAuthenticated } from "redux/selectors";

export default function requireAuth(Component) {
  const AuthenticatedComponent = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isAuthenticated = useSelector((store) =>
      selectIsAuthenticated({ store })
    );

    useEffect(() => {
      if (!isAuthenticated) {
        dispatch(routerActions.push(`/login?next=${location.pathname}`));
      }
    }, [isAuthenticated, dispatch, location.pathname]);

    return <Component {...props} />;
  };

  return AuthenticatedComponent;
}
