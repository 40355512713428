import { mergeDeepRight } from "ramda";

const initialState = {
  user: {},
  project: {},
  standup: {},
  task: {},
};

export default function reducer(entities = initialState, action = {}) {
  if (action.response && action.response.entities) {
    return mergeDeepRight(entities, action.response.entities);
  }

  return entities;
}
