import {
  PROFILE_LOAD_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
} from "redux/modules/profile";
import { LOGOUT } from "redux/modules/auth";
import { LOCATION_CHANGE } from "connected-react-router";
import { path, pathOr } from "ramda";

export default function analyticsMiddleware() {
  return (next) => (action) => {
    // Immediately call referring action. Don't block for analytics.
    next(action);

    switch (action.type) {
      case LOCATION_CHANGE: {
        window.analytics.page();
        break;
      }
      case PROFILE_LOAD_SUCCESS:
      case PROFILE_UPDATE_SUCCESS: {
        const responseJS = action.response.toJS();

        const userId = path(["result"], responseJS);

        const traits = pathOr({}, ["entities", "user", userId], responseJS);

        window.analytics.identify(userId, traits);
        break;
      }
      case LOGOUT: {
        window.analytics.track("Logout");
        break;
      }
      default: {
        break;
      }
    }
  };
}
