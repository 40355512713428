import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { compose, createStore, applyMiddleware } from "redux";
import { getParameterByName } from "utils/url";
import { Provider } from "react-redux";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import * as serviceWorker from "./serviceWorker";
import apiMiddleware from "./redux/middleware/api";
import analyticsMiddleware from "./redux/middleware/analytics";
import routes from "./routes";
import { loginSuccess } from "redux/modules/auth";
import createRootReducers from "redux/modules/reducer";
import { ThemeProvider } from "theme-ui";
import theme from "./theme";
import { ToastContainer, Slide } from "react-toastify";
import { DndProvider } from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";

import "react-toastify/dist/ReactToastify.css";
import "draft-js/dist/Draft.css";
import "draft-js-emoji-plugin/lib/plugin.css";
import "draft-js-linkify-plugin/lib/plugin.css";

import "whatwg-fetch";

const history = createBrowserHistory();

const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

const middleware = [
  thunk,
  apiMiddleware,
  analyticsMiddleware,
  routerMiddleware(history),
];

const rootEl = document.getElementById("app");
let store;
if (process.env.NODE_ENV === "Production") {
  store = applyMiddleware(...middleware)(createStore)(
    createRootReducers(history)
  );
} else {
  store = compose(
    applyMiddleware(...middleware),
    devToolsExtension ? devToolsExtension() : (f) => f
  )(createStore)(createRootReducers(history));
}

const tokenParam = getParameterByName("token");
if (tokenParam) {
  window.localStorage.setItem("token", tokenParam);
  history.replace("/");
}

const token = window.localStorage.getItem("token");
if (token !== null) {
  store.dispatch(loginSuccess(token));
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <DndProvider options={HTML5toTouch}>
        <ConnectedRouter history={history}>{routes}</ConnectedRouter>
        <ToastContainer autoClose={3000} hideProgressBar transition={Slide} />
      </DndProvider>
    </ThemeProvider>
  </Provider>,
  rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
