import { UNAUTHORIZED_STATUS_CODE, API_UNAUTHORIZED } from "constants/auth";
import ApiClient from "helpers/api";
import { ERR_CONNECTION_REFUSED } from "redux/modules/auth";

export default function apiMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    if (typeof action === "function") {
      return action(dispatch, getState);
    }

    const {
      auth: { token },
    } = getState();

    const { request, types, schema, ...rest } = action;

    if (!request) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;

    next({ ...rest, type: REQUEST });

    return request(new ApiClient({ token, schema }))
      .then((response) => {
        next({ ...rest, response: response.parsedBody, type: SUCCESS });
        return response;
      })
      .catch((error) => {
        if (error.status === UNAUTHORIZED_STATUS_CODE) {
          next({ type: API_UNAUTHORIZED });
        }

        if (error.response) {
          error.response.then((response) => {
            next({ ...rest, response, error, type: FAILURE });
          });
        } else {
          const response = {
            parsedBody: {
              error: ERR_CONNECTION_REFUSED,
              message: "CONNECTION_REFUSED",
            },
          };

          next({
            ...rest,
            response,
            error: { ...error, response, status: ERR_CONNECTION_REFUSED },
            type: FAILURE,
          });
        }

        return error;
      });
  };
}
