/** @jsx jsx */
import { jsx, Button } from "theme-ui";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { updateDetails, updateTitle } from "redux/modules/header";
import {
  selectActiveProjectsList,
  selectArchivedProjectsList,
  selectProjectListLoading,
} from "redux/selectors";
import { getArchivedProjects } from "redux/modules/projects";
import ProjectList from "components/ProjectList";
import Page from "containers/Page";

const Dashboard = () => {
  const dispatch = useDispatch();

  const activeProjects = useSelector((store) =>
    selectActiveProjectsList({ store })
  );
  const archivedProjects = useSelector((store) =>
    selectArchivedProjectsList({ store })
  );
  const isLoading = useSelector((store) => selectProjectListLoading({ store }));

  useEffect(() => {
    dispatch(updateTitle("Your Projects"));
    dispatch(updateDetails(""));
    dispatch(getArchivedProjects());
  }, [dispatch]);

  const AddNewProjectButton = () => (
    <Link to="/projects/new">
      <Button variant="secondary">Add New Project</Button>
    </Link>
  );

  return (
    <Page title="StandupHub | Your Projects">
      <ProjectList
        activeProjects={activeProjects}
        archivedProjects={archivedProjects}
        isLoading={isLoading}
        title="All Projects"
        headerAction={<AddNewProjectButton />}
      />
    </Page>
  );
};

export default Dashboard;
