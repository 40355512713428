import { schema } from "normalizr";

// User Schema
const userSchema = new schema.Entity(
  "user",
  {},
  {
    idAttribute: "id",
  }
);

// Project Schema
const projectSchema = new schema.Entity(
  "project",
  {},
  {
    idAttribute: "uuid",
  }
);

// Event Schema
const eventSchema = new schema.Entity(
  "event",
  {},
  {
    idAttribute: "id",
  }
);

// Task Schema
const taskSchema = new schema.Entity(
  "task",
  {},
  {
    idAttribute: "id",
  }
);

// Schema Nesting
userSchema.define({
  projects: [projectSchema],
  tasks: [taskSchema],
});

projectSchema.define({
  users: [userSchema],
});

taskSchema.define({
  user: userSchema,
  events: [eventSchema],
  project: projectSchema,
});

export const User = userSchema;
export const UserList = [userSchema];
export const Project = projectSchema;
export const ProjectList = [projectSchema];
export const Task = taskSchema;
export const Event = eventSchema;
export const EventList = [eventSchema];
export const TaskList = [taskSchema];
