/** @jsx jsx */
import { jsx, Button, Flex, Box } from "theme-ui";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Page from "containers/Page";
import Header from "components/Header";
import Main from "components/Main";
import { createProject } from "redux/modules/projects";
import { tz } from "moment-timezone";
import ProjectForm from "components/ProjectForm";

const ProjectNew = () => {
  const dispatch = useDispatch();
  return (
    <Page title="StandupHub | New Project">
      <Header text="Create New Project" />
      <Main>
        <Box mx={[4, 0, 0]}>
          <ProjectForm
            initValues={{
              name: "",
              meeting_url: "",
              pin: "",
              phone_number: "",
              access_code: "",
              timezone: tz.guess(),
              time: -1,
              days: [1],
            }}
            onSubmit={(data) => dispatch(createProject(data))}
          >
            <Flex sx={{ marginY: 4 }}>
              <Button
                type="submit"
                variant="primary"
                sx={{ marginRight: 2, width: "100px" }}
              >
                Create
              </Button>
              <Link to="/">
                <Button
                  variant="secondary"
                  sx={{ marginLeft: 2, width: "100px" }}
                >
                  Cancel
                </Button>
              </Link>
            </Flex>
          </ProjectForm>
        </Box>
      </Main>
    </Page>
  );
};

export default ProjectNew;
