/** @jsx jsx */
import { useEffect } from "react";
import { jsx } from "theme-ui";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { SERVER_DATE_FORMAT } from "constants/api";
import Page from "containers/Page";
import { getDetails } from "utils/details";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import { parse } from "query-string";
import { getProjectUsers } from "redux/modules/users";
import { updateTitle, updateDetails } from "redux/modules/header";
import { getStandups } from "redux/modules/standups";
import { selectActiveProject, selectActiveComposers } from "redux/selectors";
import { routerActions } from "connected-react-router";
import Standup from "containers/Standup";
import DateNavigation from "components/DateNavigation";
import { CLIENT_DATE_FORMAT } from "constants/api";
import Team from "containers/Team";

const Project = () => {
  const { projectUuid } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { date: dateParam } = parse(location.search);

  const serverDate = moment(dateParam).format(SERVER_DATE_FORMAT);

  const project = useSelector((store) =>
    selectActiveProject({ store, projectUuid })
  );

  const composers = useSelector((store) =>
    selectActiveComposers({ store, date: serverDate, projectUuid })
  );

  const projectId = project.id;
  const title = project.name;

  useEffect(() => {
    if (projectId) {
      dispatch(getStandups(projectId, dateParam));
      dispatch(getProjectUsers(projectId));
    }
  }, [projectId, dateParam, dispatch]);

  useEffect(() => {
    dispatch(updateTitle(title));
  }, [dispatch, title]);

  useEffect(() => {
    dispatch(
      updateDetails(
        getDetails(project.days || [], project.time, project.timezone)
      )
    );
  }, [dispatch, project]);

  const push = (route) => dispatch(routerActions.push(route));

  const handleDateChange = (date) => {
    if (moment().startOf("day").isSame(moment(date))) {
      return dispatch(routerActions.push(location.pathname));
    }

    dispatch(routerActions.push({ ...location, search: `?date=${date}` }));
  };

  return (
    <Page
      title={`StandupHub | ${title}`}
      activeProject={project}
      location={location}
      details={getDetails(project.days || [], project.time, project.timezone)}
    >
      <DateNavigation
        projectUuid={project.uuid}
        date={moment(dateParam).format(CLIENT_DATE_FORMAT)}
        onDateChange={handleDateChange}
      />
      <Switch>
        <Route
          exact
          path="/projects/:unusedProjectSlug/:projectUuid/me"
          render={(routeProps) => <Standup {...routeProps} project={project} />}
        />
        <Route
          exact
          path="/projects/:unusedProjectSlug/:projectUuid"
          render={(routeProps) => (
            <Team
              {...routeProps}
              project={project}
              location={location}
              push={push}
              composers={composers}
            />
          )}
        />
      </Switch>
    </Page>
  );
};

export default Project;
