/** @jsx jsx */
import debounce from "lodash/debounce";
import isEmptyOrNil from "utils/isEmptyOrNil";
import { Fragment, useCallback, useState, useEffect } from "react";
import validations from "utils/validations";
import { useSelector, useDispatch } from "react-redux";
import { jsx, Box, Button, Flex, Text } from "theme-ui";
import Typeahead from "components/Typeahead";
import UserSearchResult from "./search-result";
import PropTypes from "prop-types";
import {
  setUserPermissionsOnProjectById,
  removeUserFromProjectById,
} from "redux/modules/projects";
import { getProjectUsers } from "redux/modules/users";
import { searchUsers } from "redux/modules/search";
import { inviteUser } from "redux/modules/invite";
import teammateIcon from "images/teammate-icon.png";
import { selectProjectUsers, selectSearchResults } from "redux/selectors";

const SEARCH_DEBOUNCE = 200;

const UserManage = ({ project = {} }) => {
  const dispatch = useDispatch();

  const users = useSelector((store) =>
    selectProjectUsers({ store, projectUuid: project.uuid })
  );

  const userSearchResults = useSelector((store) =>
    selectSearchResults({ store, projectUuid: project.uuid })
  );

  useEffect(() => {
    if (!isEmptyOrNil(project)) {
      dispatch(getProjectUsers(project.id));
    }
  }, [project, dispatch]);

  const [searchInput, setSearchInput] = useState("");

  const handleInviteUserByEmail = (email = "") => () => {
    dispatch(inviteUser(project.id, email, true)).then((response) => {
      setSearchInput("");
      return response;
    });
  };

  const debounceSearchUsers = useCallback(
    debounce((input) => dispatch(searchUsers(input)), SEARCH_DEBOUNCE),
    []
  );

  const handleSearchInputChange = (searchInput) => {
    setSearchInput(searchInput);
    debounceSearchUsers(searchInput);
  };

  const handleSearchResultSelect = (value) => {
    dispatch(setUserPermissionsOnProjectById(project.id, value.id));
  };

  const handlePermissionsChange = (userId) => ({ target }) => {
    dispatch(
      setUserPermissionsOnProjectById(project.id, userId, !target.checked)
    );
  };

  const handleUserRemove = (userId) => () => {
    dispatch(removeUserFromProjectById(project.id, userId));
  };

  const renderEmptyTypeahead = () => {
    if (validations.email(searchInput)) {
      return (
        <Box
          sx={{ marginTop: 1, cursor: "pointer" }}
          onClick={handleInviteUserByEmail(searchInput)}
        >
          <Text>Send invite to {searchInput}</Text>
        </Box>
      );
    }

    return (
      <Box sx={{ marginTop: 1 }}>
        <Text>No users found matching: {searchInput}</Text>
      </Box>
    );
  };

  if (isEmptyOrNil(project)) {
    return "Loading...";
  }

  return (
    <Fragment>
      <Box>
        <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
          <img sx={{ marginRight: 2 }} src={teammateIcon} alt="teammate icon" />
          <Text id="userManageTitle" variant="subhead">
            Teammates
          </Text>
        </Flex>
        <Box sx={{ marginTop: 3 }}>
          {users.map(({ id, name, is_composer }) => (
            <Flex key={id} sx={css.row}>
              <Text aria-label={name} id="userManageName" sx={css.ellipsis}>
                {name}
              </Text>
              {project.is_owner && (
                <Flex sx={{ alignItems: "center" }}>
                  <input
                    aria-labelledby="userManageTitle userManageName readOnly"
                    type="checkbox"
                    checked={!is_composer}
                    onChange={handlePermissionsChange(id)}
                  />
                  <Text id="readOnly" sx={css.ellipsis}>
                    Read only
                  </Text>
                  <Button
                    type="button"
                    aria-labelledby="userManageTitle userManageName"
                    variant="minimal"
                    color="blue"
                    onClick={handleUserRemove(id)}
                    sx={{ textDecoration: "underline" }}
                  >
                    Remove
                  </Button>
                </Flex>
              )}
            </Flex>
          ))}
        </Box>
      </Box>
      {project.is_owner && (
        <Box sx={{ marginTop: 3 }}>
          <Typeahead
            onInputChange={handleSearchInputChange}
            onSelect={handleSearchResultSelect}
            value={searchInput}
            emptyResults={renderEmptyTypeahead()}
            emptyResultsLabel="No user with name or email"
            placeholder="Add or invite a user to the project"
            label="User's name or email"
            resultItem={<UserSearchResult />}
            results={userSearchResults}
          />
        </Box>
      )}
    </Fragment>
  );
};

const css = {
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: [5, 6],
  },
  ellipsis: {
    fontWeight: "body",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

UserManage.propTypes = {
  project: PropTypes.object,
};

export default UserManage;
