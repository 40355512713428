/** @jsx jsx */
import classNames from "classnames";
import PropTypes from "prop-types";
import { jsx, Flex, Button } from "theme-ui";

const DAYS_OF_WEEK = [
  {
    value: 0,
    label: "Sun",
  },
  {
    value: 1,
    label: "Mon",
  },
  {
    value: 2,
    label: "Tue",
  },
  {
    value: 3,
    label: "Wed",
  },
  {
    value: 4,
    label: "Thu",
  },
  {
    value: 5,
    label: "Fri",
  },
  {
    value: 6,
    label: "Sat",
  },
];

const DayPicker = ({ selectedDays, onDayChange }) => {
  const handleClick = (value) => () => {
    onDayChange(value);
  };

  return (
    <Flex sx={css.btnGroup}>
      {DAYS_OF_WEEK.map((d) => {
        const active = selectedDays.includes(d.value);
        return (
          <Button
            className={classNames({
              active,
            })}
            type="button"
            role="switch"
            aria-checked={active}
            key={d.value}
            onClick={handleClick(d.value)}
            sx={css.btnToggle}
          >
            {d.label}
          </Button>
        );
      })}
    </Flex>
  );
};

DayPicker.propTypes = {
  selectedDays: PropTypes.array.isRequired,
  onDayChange: PropTypes.func.isRequired,
};

const css = {
  btnGroup: {
    flexWrap: "wrap",
    justifyContent: ["center", "start", "start"],
  },
  btnToggle: {
    background: "white",
    border: "1px solid",
    borderColor: "dark.2",
    borderRadius: "1px",
    color: "primary",
    display: "flex",
    fontSize: 1,
    lineHeight: "50px",
    mr: [2, 4, 4],
    mb: 2,
    width: 7,
    height: 7,
    alignItems: "center",
    justifyContent: "center",
    transition: ".15s ease-out",

    "&:hover": {
      color: "white",
    },

    "&.active": {
      backgroundColor: "primary",
      color: "white",
    },
    "&:last-of-type": {
      mr: "0px",
    },
  },
};

export default DayPicker;
