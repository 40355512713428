/** @jsx jsx */
import { useState, useEffect } from "react";
import { jsx } from "theme-ui";
import isEmptyOrNil from "utils/isEmptyOrNil";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import validate, { MIN_PASSWORD_LENGTH } from "utils/validations";
import { routerActions } from "connected-react-router";
import { signup } from "redux/modules/auth";
import { Link } from "react-router-dom";
import { Box, Button, Text, Flex, Heading } from "theme-ui";

import { ReactComponent as GithubIcon } from "images/github-logo.svg";
import ShinyHappyPeopleDesktop from "images/shiny-happy-people-desktop.svg";
import ShinyHappyPeopleMobile from "images/shiny-happy-people-mobile.svg";
import FancyInput from "components/FancyInput";

import { selectIsAuthenticated, selectAuthMessage } from "redux/selectors";

const Signup = () => {
  const [errors, setErrors] = useState([]);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((store) =>
    selectIsAuthenticated({ store })
  );

  const message = useSelector((store) => selectAuthMessage({ store }));

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(routerActions.replace("/"));
    }
  }, [formState, isAuthenticated, dispatch, errors]);

  useEffect(() => {
    if (!isEmptyOrNil(message)) {
      setErrors([message]);
    }
  }, [message]);

  const validateForm = (formValues) => {
    const errors = [];

    if (!isEmptyOrNil(message)) {
      errors.push(message);
    }

    if (!validate.presence(formValues.name)) {
      errors.push("Name is required.");
    }

    if (
      !validate.presence(formValues.email) ||
      !validate.email(formValues.email)
    ) {
      errors.push("Please enter a valid email.");
    }

    if (!validate.passwordLength(formValues.password.length)) {
      errors.push(
        `Your password must be greater or equal to ${MIN_PASSWORD_LENGTH} characters.`
      );
    }

    return errors;
  };

  const handleFormChange = (field) => ({ target }) => {
    setFormState((formState) => ({ ...formState, [field]: target.value }));
  };

  const handleSignup = (e) => {
    e.preventDefault();
    const errors = validateForm(formState);

    if (errors.length) {
      return setErrors(errors);
    }

    const { name, email, password } = formState;
    dispatch(signup({ name, email, password, passwordConfirmation: password }));
  };

  return (
    <Flex
      color="light.0"
      bg="primary"
      sx={{
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>StandupHub | Signup</title>
      </Helmet>
      <Box sx={css.bg}>
        <Box sx={{ maxWidth: ["100%", "50vw", "25vw"], mx: "auto" }}>
          <Heading sx={{ textAlign: "center", marginBottom: 3 }}>
            Your daily standups are going to be so good.
          </Heading>
          {!isEmptyOrNil(errors) && (
            <ul className="flash-message flash-error flash-align-left">
              {errors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          )}
          <Button
            as="a"
            variant="github"
            href={`${process.env.REACT_APP_API_SERVER}/auth/github?redirect_url=${window.location.href}`}
          >
            <GithubIcon />
            <Text sx={{ mx: 2 }}>Continue with Github</Text>
          </Button>
          <div sx={{ textAlign: "center", my: 3 }}>or</div>
          <form onSubmit={handleSignup}>
            <fieldset sx={css.fieldset}>
              <FancyInput
                type="text"
                label="Name"
                value={formState.name}
                onChange={handleFormChange("name")}
                placeholder="What do you go by..."
                required
              />
            </fieldset>
            <fieldset sx={css.fieldset}>
              <FancyInput
                label="Email"
                type="email"
                value={formState.email}
                onChange={handleFormChange("email")}
                placeholder="example@email.com"
                required
              />
            </fieldset>
            <fieldset sx={css.fieldset}>
              <FancyInput
                label="Password"
                type="password"
                value={formState.password}
                onChange={handleFormChange("password")}
                placeholder="Something super secure"
                required
              />
            </fieldset>
            <aside
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                type="submit"
                variant="secondary"
                sx={{ mb: 3, width: "100%" }}
              >
                Signup
              </Button>
              <Button
                as={Link}
                to="/login"
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "card",
                }}
              >
                Have an account? Login
              </Button>
            </aside>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

const css = {
  bg: {
    width: "100%",
    mt: [6, 9, 9],
    mx: [4, 0, 0],
    backgroundRepeat: "no-repeat",
    backgroundPosition: ["0% 100%", null, "50% 100%"],
    backgroundImage: [
      `url(${ShinyHappyPeopleMobile})`,
      `url(${ShinyHappyPeopleDesktop})`,
      `url(${ShinyHappyPeopleDesktop})`,
    ],
  },
  fieldset: {
    border: "none",
    padding: 0,
    mb: 3,
  },
};

export default Signup;
