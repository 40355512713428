import React from "react";
import { Route, Switch } from "react-router-dom";
import requireAuth from "helpers/requireAuth";
import App from "containers/App";
import ForgotPassword from "containers/ForgotPassword";
import Login from "containers/Login";
import ResetPassword from "containers/ResetPassword";
import Signup from "containers/Signup";
import NotFound from "containers/NotFound";
import StyleGuide from "containers/Styleguide";

const routes = (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route exact path="/reset-password/:token" component={ResetPassword} />
    {process.env.NODE_ENV !== "production" && (
      <Route exact path="/styleguide" component={StyleGuide} />
    )}
    <Route path="/" component={requireAuth(App)} />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default routes;
