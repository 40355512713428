/** @jsx jsx */
import { jsx, Button, Box, Flex, Heading, Text } from "theme-ui";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { routerActions } from "connected-react-router";
import { forgotPassword } from "redux/modules/password";
import { Link } from "react-router-dom";
import FancyInput from "components/FancyInput";
import ShinyHappyPeopleDesktop from "images/shiny-happy-people-desktop.svg";
import ShinyHappyPeopleMobile from "images/shiny-happy-people-mobile.svg";
import { selectIsAuthenticated } from "redux/selectors";

const SentMessage = () => (
  <div>
    <Text sx={css.txt}>
      Check your email for a link to reset your password. If it doesn't arrive
      in the next few minutes, please check your spam folder.
    </Text>
    <Button variant="secondary" as={Link} sx={css.btnLink} to="/login">
      Return to Login
    </Button>
  </div>
);

const ForgotForm = ({
  onForgotPassword,
  onFormChange,
  email,
  emailFormatError,
}) => (
  <form onSubmit={onForgotPassword}>
    {emailFormatError && (
      <Text data-cy="error-message" sx={css.err}>
        Email address is not formatted correctly, please try again.
      </Text>
    )}
    <Text sx={css.txt}>
      Enter your email address and we'll send you a link to reset your password.
    </Text>
    <fieldset sx={css.fieldset}>
      <FancyInput
        label="Email"
        type="email"
        value={email}
        onChange={onFormChange("email")}
        placeholder="example@email.com"
        required
        sx={css.input}
      />
    </fieldset>
    <Button type="submit" variant="secondary" sx={css.btn}>
      <Text>Send password reset email</Text>
    </Button>
  </form>
);

const ErrorMessage = () => (
  <div>
    <Text sx={css.err}>
      Something went wrong, cannot reset password at this time. Please try again
      later.
    </Text>
    <Button as={Link} sx={css.btnLink} to="/login">
      <Text>Return to Login </Text>
    </Button>
  </div>
);

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState(false);

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((store) =>
    selectIsAuthenticated({ store })
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(routerActions.replace("/settings"));
    }
  }, [isAuthenticated, dispatch]);

  const handleFormChange = (field) => ({ target }) => {
    setEmail(target.value);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!/^.+@[^.].*\.[a-z]{2,}$/.test(email)) {
      setEmailFormatError(true);
      setEmailSent(false);
      return;
    }

    const res = await dispatch(forgotPassword(email));

    if (res.status >= 400 && res.status < 600) {
      setEmailError(true);
      setEmailSent(false);
    } else {
      setEmailError(false);
      setEmailSent(true);
    }
  };

  return (
    <Flex color="light.0" bg="primary" sx={css.flex}>
      <Helmet>
        <title>StandupHub | Forgot Password</title>
      </Helmet>
      <Box sx={css.bg}>
        <Box sx={css.box}>
          <Heading variant="heading" sx={css.heading}>
            Forgot Password
          </Heading>
          {emailError && <ErrorMessage />}
          {emailSent && <SentMessage />}
          {!emailError && !emailSent && (
            <ForgotForm
              onFormChange={handleFormChange}
              onForgotPassword={handleForgotPassword}
              email={email}
              emailFormatError={emailFormatError}
            />
          )}
        </Box>
      </Box>
    </Flex>
  );
};

const css = {
  txt: {
    textAlign: "center",
  },
  err: {
    textAlign: "center",
    mb: 3,
  },
  btn: {
    marginTop: 3,
    width: "100%",
  },
  btnLink: {
    display: "flex",
    marginTop: 3,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    mb: 3,
  },
  box: {
    maxWidth: ["100%", "50vw", "25vw"],
    mx: "auto",
  },
  flex: {
    justifyContent: "center",
    minHeight: "100vh",
  },
  input: {
    backgroundColor: "white",
    border: "1px solid",
    borderColor: "dark.2",
    borderRadius: 1,
    marginTop: 3,
  },
  fieldset: {
    border: "none",
    padding: 0,
  },
  bg: {
    width: "100%",
    mt: [6, 9, 9],
    mx: [4, 0, 0],
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 100%",
    backgroundImage: [
      `url(${ShinyHappyPeopleMobile})`,
      `url(${ShinyHappyPeopleDesktop})`,
      `url(${ShinyHappyPeopleDesktop})`,
    ],
  },
};

export default ForgotPassword;
