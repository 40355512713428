/** @jsx jsx */
import { jsx, MenuButton, Text, Button } from "theme-ui";
import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { darken } from "@theme-ui/color";
import { motion, AnimatePresence } from "framer-motion";
import BlockLetter from "components/BlockLetter";
import getProjectColor from "utils/colors";
import { ReactComponent as StandupHubLogo } from "images/standuphub-logo.svg";
import { selectActiveProjectsList, selectActiveProject } from "redux/selectors";

const bgVariants = {
  open: {
    scaleY: 1,
    transition: {
      type: "spring",
      stiffness: 50,
      restDelta: 2,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const listItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const listVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { projectUuid } = useParams();
  const projects = useSelector((store) => selectActiveProjectsList({ store }));
  const project = useSelector((store) =>
    selectActiveProject({ store, projectUuid })
  );

  useEffect(() => {
    const overflow = isOpen ? "hidden" : "auto";
    document.body.style.overflow = overflow;
  }, [isOpen]);

  return (
    <div sx={{ ...sx.outer, position: isOpen ? "static" : "sticky" }}>
      <div sx={sx.title}>
        <AnimatePresence>
          {isOpen ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <StandupHubLogo sx={sx.logo} />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {project.name}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <motion.nav initial={false} animate={isOpen ? "open" : "closed"}>
        <MenuButton
          sx={{
            zIndex: 1000,
          }}
          onClick={() => setIsOpen(!isOpen)}
        />
        <motion.div
          sx={{
            position: "fixed",
            right: 0,
            bottom: 0,
            bg: "primary",
            transformOrigin: "top center",
            width: "100vw",
            top: "64px",
            zIndex: 800,
          }}
          variants={bgVariants}
        />
        <motion.ul
          variants={listVariants}
          sx={{
            pointerEvents: isOpen ? "auto" : "none",
            position: "fixed",
            listStyle: "none",
            zIndex: 900,
            m: "0px",
            pl: "0px",
            width: "100%",
            top: "64px",
            overflow: "scroll",
            height: "calc(100vh - 64px)",
          }}
        >
          {projects && projects.length > 0 && (
            <Fragment>
              <motion.li variants={listItemVariants}>
                <Text sx={{ px: 2, my: 4, color: "white" }}>My Projects</Text>
              </motion.li>
              {projects.map(({ id, uuid, slug, name }) => {
                const active = uuid === project.uuid;
                return (
                  <motion.li key={uuid} variants={listItemVariants}>
                    <Button
                      to={`/projects/${slug}/${uuid}/me`}
                      as={Link}
                      onClick={() => setIsOpen(false)}
                      sx={{
                        ...sx.button,
                        bg: active ? darken("primary", 0.08) : "primary",
                      }}
                    >
                      <BlockLetter
                        color={getProjectColor(id)}
                        letter={name.charAt(0)}
                        size={3}
                      />
                      <span
                        sx={{
                          marginX: 2,
                          width: "66.6%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {name}
                      </span>
                    </Button>
                  </motion.li>
                );
              })}
            </Fragment>
          )}
          <motion.li variants={listItemVariants}>
            <Button
              sx={sx.button}
              as={Link}
              to="/"
              onClick={() => setIsOpen(false)}
            >
              All Projects
            </Button>
          </motion.li>
          <motion.li variants={listItemVariants}>
            <Button
              sx={sx.button}
              as={Link}
              to="/settings"
              onClick={() => setIsOpen(false)}
            >
              Settings
            </Button>
          </motion.li>
        </motion.ul>
      </motion.nav>
    </div>
  );
};

const sx = {
  outer: {
    bg: "primary",
    display: ["flex", "none"],
    height: 7,
    zIndex: 800,
  },
  title: {
    display: "flex",
    flex: 1,
    order: 1,
    px: 7,
    color: "light.0",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  logoSection: {
    px: 3,
    alignItems: "center",
    height: 7,
    mb: 4,
    justifyContent: "center",
  },
  logo: {
    width: "100%",
    minWidth: "100px",
    maxWidth: "155px",
    "& path": {
      fill: "white",
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    height: 7,
    px: 3,
  },
};

export default HamburgerMenu;
