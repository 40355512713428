export const MIN_PASSWORD_LENGTH = 4;

export function email(address) {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(address);
}

export function gte(field, n) {
  return field >= n;
}

export function lte(field, n) {
  return field <= n;
}

export function presence(field) {
  return field !== "";
}

export function passwordLength(passwordLength) {
  return gte(passwordLength, MIN_PASSWORD_LENGTH);
}

export default {
  email,
  gte,
  lte,
  presence,
  passwordLength,
};
