import { API_VERSION } from "constants/api";
import { UserList } from "schemas";

export const SEARCH_USERS_LOAD = "SEARCH_USERS_LOAD";
export const SEARCH_USERS_LOAD_SUCCESS = "SEARCH_USERS_LOAD_SUCCESS";
export const SEARCH_USERS_LOAD_FAILURE = "SEARCH_USERS_LOAD_FAILURE";

const initialState = {
  users: [],
};

export default function reducer(search = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_USERS_LOAD_SUCCESS:
      return {
        users: action.response.result,
      };
    default:
      return search;
  }
}

export function searchUsers(query = "") {
  return {
    types: [
      SEARCH_USERS_LOAD,
      SEARCH_USERS_LOAD_SUCCESS,
      SEARCH_USERS_LOAD_FAILURE,
    ],
    request: (api) => api.get(`/${API_VERSION}/search/users?q=${query}`),
    schema: UserList,
  };
}
