import React, { useState } from "react";
import Blur from "components/Blur";
import PropTypes from "prop-types";
import FancyInput from "components/FancyInput";

const Typeahead = ({
  clearOnSelect = true,
  emptyResults = "No Results",
  placeholder,
  resultItem,
  results = [],
  value,
  label,
  onInputChange = () => undefined,
  onSelect = () => undefined,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = ({ target }) => {
    target.value.length > 0 ? setIsOpen(true) : setIsOpen(false);

    if (target.value !== value) {
      onInputChange(target.value);
    }
  };

  const handleItemClick = (item, index) => () => {
    if (clearOnSelect) {
      setIsOpen(false);
      onInputChange("");
    }
    onSelect(item, index);
  };

  return (
    <Blur onBlur={() => setIsOpen(false)}>
      <FancyInput
        type="text"
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)}
      />
      {isOpen && (
        <div>
          {results.length === 0 && <div>{emptyResults}</div>}
          {results.map((result, i) =>
            React.cloneElement(resultItem, {
              key: i,
              result,
              onClick: handleItemClick(result, i),
            })
          )}
        </div>
      )}
    </Blur>
  );
};

Typeahead.propTypes = {
  clearOnSelect: PropTypes.bool,
  emptyResults: PropTypes.node,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  resultItem: PropTypes.element.isRequired,
  results: PropTypes.array.isRequired,
  value: PropTypes.string,
  onInputChange: PropTypes.func,
  onSelect: PropTypes.func,
};

export default Typeahead;
