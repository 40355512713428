/** @jsx jsx */
import { jsx, Box, Text, Avatar, Button } from "theme-ui";
import PropTypes from "prop-types";

const UserSearchResult = ({ result = {}, onClick }) => (
  <Button
    variant="secondary"
    sx={sx.button}
    aria-label={result.name}
    onClick={onClick}
  >
    <Avatar src={result.avatar} alt={result.name} />
    <Box sx={{ marginLeft: 2, textAlign: "left" }}>
      <Text>{result.name}</Text>
      <Text>{result.email}</Text>
    </Box>
  </Button>
);

const sx = {
  button: {
    display: "flex",
    flexDirection: "row",
    marginTop: 2,
    width: "100%",
    alignItems: "center",
  },
};

UserSearchResult.propTypes = {
  result: PropTypes.object,
  onClick: PropTypes.func,
};

export default UserSearchResult;
