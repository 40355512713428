import moment from "moment";
import {
  API_VERSION,
  CLIENT_DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from "constants/api";
import { UserList, TaskList } from "schemas";
import { pathOr, path, groupBy, map } from "ramda";

import { TASK_CREATE_SUCCESS } from "./tasks";

export const STANDUPS_LOAD = "STANDUPS_LOAD";
export const STANDUPS_LOAD_SUCCESS = "STANDUPS_LOAD_SUCCESS";
export const STANDUPS_LOAD_FAILURE = "STANDUPS_LOAD_FAILURE";

export const TASKS_LOAD = "TASKS_LOAD";
export const TASKS_LOAD_SUCCESS = "TASKS_LOAD_SUCCESS";
export const TASKS_LOAD_FAILURE = "TASKS_LOAD_FAILURE";

const initialState = {
  isLoading: false,
};

export default function reducer(standups = initialState, action = {}) {
  const { date, projectId, userId, response } = action;

  switch (action.type) {
    case STANDUPS_LOAD:
      return {
        ...standups,
        isLoading: true,
      };
    case STANDUPS_LOAD_SUCCESS:
      const { entities, result: standup } = response;

      const tasks = pathOr([], ["tasks"], standup).map((t) =>
        path(["task", t], entities)
      );

      const userTasks = map(
        (u) => [...new Set(u.map((t) => t.id))],
        groupBy((t) => t.user, tasks)
      );

      return {
        ...standups,
        isLoading: false,
        [date]: {
          ...standups[date],
          [projectId]: {
            tasks: [...new Set(standup.tasks)],
            users: [...new Set(standup.users)],
            userTasks,
          },
        },
      };
    case STANDUPS_LOAD_FAILURE:
      return {
        ...standups,
        isLoading: false,
      };
    case TASKS_LOAD_SUCCESS: {
      const { result } = response;
      const taskIds = [...new Set(result.map((t) => t))];

      return {
        ...standups,
        [date]: {
          ...standups[date],
          [projectId]: {
            ...standups[date][projectId],
            userId: taskIds,
          },
        },
      };
    }
    case TASK_CREATE_SUCCESS: {
      const taskId = response.result;

      const userTasks = pathOr(
        [],
        [date, projectId, "userTasks", userId],
        standups
      );

      return {
        ...standups,
        [date]: {
          ...standups[date],
          [projectId]: {
            ...standups[date][projectId],
            userTasks: {
              ...standups[date][projectId].userTasks,
              [userId]: [...userTasks, taskId],
            },
          },
        },
      };
    }
    default:
      return standups;
  }
}

export function getStandupForUser(
  projectId = "",
  userId = "",
  date = moment().format(CLIENT_DATE_FORMAT)
) {
  const serverDate = moment(date).format(SERVER_DATE_FORMAT);
  return {
    types: [TASKS_LOAD, TASKS_LOAD_SUCCESS, TASKS_LOAD_FAILURE],
    request: (api) =>
      api.get(
        `/${API_VERSION}/standups/${serverDate}/${projectId}/users/${userId}`
      ),
    schema: TaskList,
    date: serverDate,
    projectId,
    userId,
  };
}

export function getStandups(
  projectId = "",
  date = moment().format(CLIENT_DATE_FORMAT)
) {
  const serverDate = moment(date).format(SERVER_DATE_FORMAT);
  return {
    types: [STANDUPS_LOAD, STANDUPS_LOAD_SUCCESS, STANDUPS_LOAD_FAILURE],
    request: (api) =>
      api.get(`/${API_VERSION}/standups/${serverDate}/${projectId}`),
    schema: {
      tasks: TaskList,
      users: UserList,
    },
    date: serverDate,
    projectId,
  };
}
