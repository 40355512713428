import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { getProfile } from "redux/modules/profile";
import Dashboard from "containers/Dashboard";
import ProjectEdit from "containers/ProjectEdit";
import ProjectNew from "containers/ProjectNew";
import Project from "containers/Project";
import Settings from "containers/Settings";
import { getArchivedProjects, getActiveProjects } from "redux/modules/projects";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getActiveProjects());
    dispatch(getArchivedProjects());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>StandupHub</title>
      </Helmet>
      <Switch>
        <Route exact path="/settings" component={Settings} />
        <Route
          exact
          path="/projects/:unusedProjectSlug/:projectUuid/edit"
          component={ProjectEdit}
        />
        <Route exact path="/projects/new" component={ProjectNew} />
        <Route
          path="/projects/:unusedProjectSlug/:projectUuid"
          component={Project}
        />
        <Route exact path="/" component={Dashboard} />
      </Switch>
    </>
  );
};

export default App;
