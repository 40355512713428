/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const Main = ({ className, children }) => (
  <main
    className={className}
    sx={{
      gridArea: "content",
    }}
  >
    {children}
  </main>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
