import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "./auth";
import compose from "./compose";
import entities from "./entities";
import header from "./header";
import integrations from "./integrations";
import profile from "./profile";
import projects from "./projects";
import search from "./search";
import standups from "./standups";
import users from "./users";
import invite from "./invite";

const createRootReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    compose,
    entities,
    header,
    invite,
    profile,
    projects,
    search,
    standups,
    integrations,
    users,
  });

export default createRootReducers;
