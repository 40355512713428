import { API_VERSION } from "constants/api";
import { Project, ProjectList } from "schemas";
import { getProjectUsers } from "redux/modules/users";
import { routerActions } from "connected-react-router";

import { success, error } from "utils/notifications";

export const PROJECTS_LOAD = "PROJECTS_LOAD";
export const PROJECTS_LOAD_SUCCESS = "PROJECTS_LOAD_SUCCESS";
export const PROJECTS_LOAD_FAILURE = "PROJECTS_LOAD_FAILURE";

export const ARCHIVED_PROJECTS_LOAD = "ARCHIVED_PROJECTS_LOAD";
export const ARCHIVED_PROJECTS_LOAD_SUCCESS = "ARCHIVED_PROJECTS_LOAD_SUCCESS";
export const ARCHIVED_PROJECTS_LOAD_FAILURE = "ARCHIVED_PROJECTS_LOAD_FAILURE";

export const PROJECT_LOAD = "PROJECT_LOAD";
export const PROJECT_LOAD_SUCCESS = "PROJECT_LOAD_SUCCESS";
export const PROJECT_LOAD_FAILURE = "PROJECT_LOAD_FAILURE";

export const CREATE_PROJECT_LOAD = "CREATE_PROJECT_LOAD";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";

export const PROJECT_UPDATE_LOAD = "PROJECT_UPDATE_LOAD";
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
export const PROJECT_UPDATE_FAILURE = "PROJECT_UPDATE_FAILURE";

export const PROJECT_REMOVE_LOAD = "PROJECT_REMOVE_LOAD";
export const PROJECT_REMOVE_SUCCESS = "PROJECT_REMOVE_SUCCESS";
export const PROJECT_REMOVE_FAILURE = "PROJECT_REMOVE_FAILURE";

export const PROJECT_RESTORE_LOAD = "PROJECT_RESTORE_LOAD";
export const PROJECT_RESTORE_SUCCESS = "PROJECT_RESTORE_SUCCESS";
export const PROJECT_RESTORE_FAILURE = "PROJECT_RESTORE_FAILURE";

export const PROJECT_ADD_USER_LOAD = "PROJECT_ADD_USER_LOAD";
export const PROJECT_ADD_USER_LOAD_SUCCESS = "PROJECT_ADD_USER_LOAD_SUCCESS";
export const PROJECT_ADD_USER_LOAD_FAILURE = "PROJECT_ADD_USER_LOAD_FAILURE";

export const PROJECT_REMOVE_USER_LOAD = "PROJECT_REMOVE_USER_LOAD";
export const PROJECT_REMOVE_USER_LOAD_SUCCESS =
  "PROJECT_REMOVE_USER_LOAD_SUCCESS";
export const PROJECT_REMOVE_USER_LOAD_FAILURE =
  "PROJECT_REMOVE_USER_LOAD_FAILURE";

const initialState = {
  isLoading: false,
  activeProjects: [],
  archivedProjects: [],
};

export default function reducer(projects = initialState, action = {}) {
  switch (action.type) {
    case PROJECT_LOAD:
    case ARCHIVED_PROJECTS_LOAD:
    case PROJECTS_LOAD:
    case PROJECT_UPDATE_LOAD:
      return {
        ...projects,
        isLoading: true,
      };
    case PROJECT_LOAD_SUCCESS:
    case PROJECT_LOAD_FAILURE:
    case ARCHIVED_PROJECTS_LOAD_SUCCESS:
      return {
        ...projects,
        isLoading: false,
        archivedProjects: action.response.result,
      };
    case PROJECTS_LOAD_SUCCESS:
      return {
        ...projects,
        isLoading: false,
        activeProjects: action.response.result,
      };
    case ARCHIVED_PROJECTS_LOAD_FAILURE:
    case PROJECTS_LOAD_FAILURE:
    case PROJECT_UPDATE_SUCCESS:
    case PROJECT_UPDATE_FAILURE:
      return {
        ...projects,
        isLoading: false,
      };
    default:
      return projects;
  }
}

export function getActiveProjects() {
  return {
    types: [PROJECTS_LOAD, PROJECTS_LOAD_SUCCESS, PROJECTS_LOAD_FAILURE],
    request: (api) => api.get(`/${API_VERSION}/projects`),
    schema: ProjectList,
  };
}

export function getArchivedProjects() {
  return {
    types: [
      ARCHIVED_PROJECTS_LOAD,
      ARCHIVED_PROJECTS_LOAD_SUCCESS,
      ARCHIVED_PROJECTS_LOAD_FAILURE,
    ],
    request: (api) => api.get(`/${API_VERSION}/projects?archived=true`),
    schema: ProjectList,
  };
}

export function getProjectById(projectId = 0) {
  return {
    types: [PROJECT_LOAD, PROJECT_LOAD_SUCCESS, PROJECT_LOAD_FAILURE],
    request: (api) => api.get(`/${API_VERSION}/projects/${projectId}`),
    schema: Project,
  };
}

export function createProject(project = {}) {
  return (dispatch) => {
    return dispatch({
      types: [
        CREATE_PROJECT_LOAD,
        CREATE_PROJECT_SUCCESS,
        CREATE_PROJECT_FAILURE,
      ],
      request: (api) => {
        return api
          .post(`/${API_VERSION}/projects`, { body: JSON.stringify(project) })
          .then((response) => {
            dispatch(getActiveProjects());
            dispatch(routerActions.push("/"));
            success("Project successfully created.");
            return response;
          })
          .catch((err) => {
            error(
              "There was a problem creating your project. Please try again later."
            );
            return err;
          });
      },
      schema: Project,
    });
  };
}

export function updateProjectById(projectId = 0, project = {}) {
  return (dispatch) => {
    return dispatch({
      types: [
        PROJECT_UPDATE_LOAD,
        PROJECT_UPDATE_SUCCESS,
        PROJECT_UPDATE_FAILURE,
      ],
      request: (api) => {
        return api
          .put(`/${API_VERSION}/projects/${projectId}`, {
            body: JSON.stringify(project),
          })
          .then((response) => {
            success("Project successfully updated.");
            return response;
          })
          .catch((response) => {
            error(
              "There was a problem updating your project. Please try again later."
            );
            return response;
          });
      },
      schema: Project,
      projectId,
    });
  };
}

export function removeProjectById(projectId = 0) {
  return (dispatch) => {
    return dispatch({
      types: [
        PROJECT_REMOVE_LOAD,
        PROJECT_REMOVE_SUCCESS,
        PROJECT_REMOVE_FAILURE,
      ],
      request: (api) => {
        return api
          .del(`/${API_VERSION}/projects/${projectId}`)
          .then((response) => {
            dispatch(getActiveProjects());
            dispatch(routerActions.push("/"));
            return response;
          });
      },
    });
  };
}

export function restoreProjectById(projectId = 0) {
  return (dispatch) => {
    return dispatch({
      types: [
        PROJECT_RESTORE_LOAD,
        PROJECT_RESTORE_SUCCESS,
        PROJECT_RESTORE_FAILURE,
      ],
      request: (api) => {
        return api
          .patch(`/${API_VERSION}/projects/${projectId}/restore`)
          .then((response) => {
            dispatch(getActiveProjects());
            dispatch(getArchivedProjects());
            success("Project successfully restored.");
            dispatch(routerActions.push("/"));
            return response;
          })
          .catch((response) => {
            error(
              "There was a problem restoring your project. Please try again later."
            );
            return response;
          });
      },
    });
  };
}

export function removeUserFromProjectById(projectId = 0, userId = 0) {
  return (dispatch) => {
    return dispatch({
      types: [
        PROJECT_REMOVE_USER_LOAD,
        PROJECT_REMOVE_USER_LOAD_SUCCESS,
        PROJECT_REMOVE_USER_LOAD_FAILURE,
      ],
      request: (api) => {
        return api
          .del(`${API_VERSION}/projects/${projectId}/permissions/${userId}`)
          .then((response) => {
            dispatch(getProjectUsers(projectId));
            return response;
          });
      },
    });
  };
}

export function setUserPermissionsOnProjectById(
  projectId = 0,
  userId = 0,
  composer = true
) {
  return (dispatch) => {
    return dispatch({
      types: [
        PROJECT_ADD_USER_LOAD,
        PROJECT_ADD_USER_LOAD_SUCCESS,
        PROJECT_ADD_USER_LOAD_FAILURE,
      ],
      request: (api) => {
        return api
          .post(`${API_VERSION}/projects/${projectId}/permissions/${userId}`, {
            body: JSON.stringify({ is_composer: composer }),
          })
          .then((response) => {
            dispatch(getProjectUsers(projectId));
            return response;
          });
      },
    });
  };
}
export const PROJECT_ADD_PROJECT_TRIGGER = "PROJECT_ADD_PROJECT_TRIGGER";
export const PROJECT_ADD_PROJECT_TRIGGER_SUCCESS =
  "PROJECT_ADD_PROJECT_TRIGGER_SUCCESS";
export const PROJECT_ADD_PROJECT_TRIGGER_FAILURE =
  "PROJECT_ADD_PROJECT_TRIGGER_FAILURE";

export function createGithubProjectTrigger(projectId) {
  return (dispatch) => {
    return dispatch({
      types: [
        PROJECT_ADD_PROJECT_TRIGGER,
        PROJECT_ADD_PROJECT_TRIGGER_SUCCESS,
        PROJECT_ADD_PROJECT_TRIGGER_FAILURE,
      ],
      request: (api) => {
        return api
          .post(`${API_VERSION}/projects/${projectId}/create_trigger`, {
            body: JSON.stringify({ source: "github" }),
          })
          .then((response) => {
            dispatch(getProjectById(projectId));
            return response;
          });
      },
    });
  };
}
