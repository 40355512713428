/** @jsx jsx */
import { jsx, Flex, Button } from "theme-ui";
import { alpha } from "@theme-ui/color";
import { Fragment } from "react";
import { ReactComponent as CopyIcon } from "images/copy.svg";
import PropTypes from "prop-types";

const sanatizeUrl = (value, linkType) => {
  switch (linkType) {
    case "phone":
      if (/^tel?:/i.test(value)) return value;
      return `tel:${value}`;
    case "http":
      if (/^https?:\/\//i.test(value)) return value;
      return `http://${value}`;
    default:
      return value;
  }
};

const truncate = (string, maxLength, startIndex, endIndex) =>
  string
    ? string.length > maxLength
      ? `${string.substring(0, startIndex)}…${string.substring(
          string.length - endIndex
        )}`
      : string
    : string;

const CopyableValue = ({
  label,
  value,
  icon,
  codeLabel,
  codeValue,
  linkType,
  shorten = true,
}) => {
  const displayValue = shorten ? truncate(value, 15, 8, 5) : value;
  const target = sanatizeUrl(value, linkType);

  return (
    <Fragment>
      {label && icon && (
        <Flex sx={{ alignItems: "center", mb: 3 }}>
          {icon}
          <div sx={{ ml: 2, fontSize: 1, fontWeight: "bold" }}>{label}</div>
        </Flex>
      )}
      <Flex sx={sx.linkBackground}>
        <a href={target} target="_blank" rel="noopener noreferrer" sx={sx.link}>
          {displayValue}
        </a>
        <Button
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(value);
          }}
          sx={sx.button}
          variant="minimal"
        >
          <CopyIcon
            alt="Icon for copying to clipboard"
            data-tip="copy-link"
            data-for="copy-link"
          />
        </Button>
      </Flex>
      {codeLabel && codeValue && (
        <div sx={{ opacity: "0.5", fontSize: 0, marginTop: 2 }}>
          {codeLabel}: {codeValue}
        </div>
      )}
    </Fragment>
  );
};

const sx = {
  linkBackground: {
    bg: alpha("dark.1", 0.1),
    justifyContent: "space-between",
    alignItems: "center",
    padding: 1,
  },
  link: {
    display: "flex",
    color: "primary",
    margin: 1,
    fontSize: 1,
    width: "100%",
    wordBreak: "break-all",
  },
  button: {
    display: "grid",
    placeItems: "center",
  },
};

CopyableValue.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.node,
  codeLabel: PropTypes.string,
  codeValue: PropTypes.string,
};

export default CopyableValue;
