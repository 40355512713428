/** @jsx jsx */
import { jsx, Button, Box, Flex, Heading, Text } from "theme-ui";
import { useState } from "react";
import isEmptyOrNil from "utils/isEmptyOrNil";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import validate, { MIN_PASSWORD_LENGTH } from "utils/validations";
import { routerActions } from "connected-react-router";
import { resetPassword } from "redux/modules/password";
import FancyInput from "components/FancyInput";
import ShinyHappyPeopleDesktop from "images/shiny-happy-people-desktop.svg";
import ShinyHappyPeopleMobile from "images/shiny-happy-people-mobile.svg";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [error, setError] = useState("");

  const [password, updatePassword] = useState("");

  const handleUpdatePassword = (password) => {
    setError("");
    updatePassword(password);
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (!validate.passwordLength(password.length)) {
      setError(
        `Your password must be greater or equal to ${MIN_PASSWORD_LENGTH} characters.`
      );
      return;
    }
    dispatch(resetPassword(params.token, password)).then(() =>
      dispatch(routerActions.push("/login"))
    );
  };

  return (
    <Flex color="light.0" bg="primary" sx={css.flex}>
      <Helmet>
        <title>StandupHub | Reset Password</title>
      </Helmet>
      <Box sx={css.bg}>
        <Box sx={css.box}>
          <Heading variant="heading" sx={css.heading}>
            Reset Password
          </Heading>
          {!isEmptyOrNil(error) && (
            <div className="flash-message flash-error flash-align-left">
              {error}
            </div>
          )}
          <Text sx={css.txt}>Enter your new password.</Text>
          <form onSubmit={handleResetPassword}>
            <fieldset sx={css.fieldset}>
              <FancyInput
                label="Password"
                type="password"
                value={password}
                onChange={({ target }) => handleUpdatePassword(target.value)}
                placeholder="Something super secure"
                required
                sx={css.input}
              />
            </fieldset>
            <Button type="submit" variant="secondary" sx={css.btn}>
              Reset Password
            </Button>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

const css = {
  txt: {
    textAlign: "center",
  },
  btn: {
    marginTop: 3,
    width: "100%",
  },
  heading: {
    textAlign: "center",
    mb: 3,
  },
  box: {
    maxWidth: ["100%", "50vw", "25vw"],
    mx: "auto",
  },
  flex: {
    justifyContent: "center",
    minHeight: "100vh",
  },
  input: {
    backgroundColor: "white",
    border: "1px solid",
    borderColor: "dark.2",
    borderRadius: 1,
    marginTop: 3,
  },
  fieldset: {
    border: "none",
    padding: 0,
  },
  bg: {
    width: "100%",
    mt: 9,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 100%",
    backgroundImage: [
      `url(${ShinyHappyPeopleMobile})`,
      `url(${ShinyHappyPeopleDesktop})`,
      `url(${ShinyHappyPeopleDesktop})`,
    ],
  },
};

export default ResetPassword;
