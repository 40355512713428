/** @jsx jsx */
import { jsx, Button } from "theme-ui";
import moment from "moment";
import { CLIENT_DATE_FORMAT } from "constants/api";

const DateCell = ({ date, projectDays, handleDateChange }) => {
  const isStandupDay = projectDays.includes(date.day());
  const isToday =
    date.format(CLIENT_DATE_FORMAT) === moment().format(CLIENT_DATE_FORMAT);
  const isPast = date < moment();

  const fontColor = isToday ? css.today : isPast ? css.pastDay : css.futureDay;

  const backgroundStyle =
    isStandupDay &&
    (isToday
      ? css.standupToday
      : isPast
      ? css.standupPastDay
      : css.standupFutureDay);

  return (
    <Button variant="calendar" onClick={() => handleDateChange(date)}>
      <span
        sx={{
          color: fontColor.color,
          fontWeight: "body",
        }}
      >
        {date.format("ddd")}
      </span>
      <div
        sx={{
          my: 2,
        }}
      >
        <span
          sx={{
            color: isStandupDay ? "light.1" : fontColor.color,
            backgroundColor: backgroundStyle.backgroundColor,
            fontWeight: "bold",
            paddingX: 1,
            paddingY: 1,
            borderRadius: 1,
          }}
        >
          {date.format("DD")}
        </span>
      </div>
    </Button>
  );
};

const css = {
  today: {
    color: "blue",
  },
  pastDay: {
    color: "dark.2",
  },
  futureDay: {
    color: "dark.0",
  },
  standupPastDay: {
    backgroundColor: "dark.2",
  },
  standupToday: {
    backgroundColor: "blue",
  },
  standupFutureDay: {
    backgroundColor: "dark.0",
  },
};

export default DateCell;
