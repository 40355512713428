/** @jsx jsx */
import { jsx, Flex, Text, Button } from "theme-ui";
import DayPicker from "components/DayPicker";
import Dropdown from "components/Dropdown";
import FancyInput from "components/FancyInput";
import CopyableValue from "components/CopyableValue";
import PropTypes from "prop-types";
import { getTimes, getTimeZones } from "utils/times";
import { Formik } from "formik";
import validationSchema from "./validation.jsx";
import { path } from "ramda";

const ProjectForm = ({
  initValues,
  onSubmit,
  children,
  addTrigger,
  triggers,
}) => {
  const handleDayChange = (value, formik) => {
    const newDays = formik.values.days || [];

    if (newDays.indexOf(value) !== -1) {
      newDays.splice(newDays.indexOf(value), 1);
    } else {
      newDays.push(value);
    }

    formik.setFieldValue("days", newDays);
  };

  const githubTrigger = (triggers || []).find(
    (t) => path(["source"], t) === "github"
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit({
          ...values,
        });

        setSubmitting(false);
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <FancyInput
            placeholder="A unique identifier for your project..."
            label="Project Name"
            value={formik.values.name}
            onChange={formik.handleChange("name")}
            required
          />
          {formik.errors.name && formik.touched.name ? (
            <Text variant="small" sx={css.errorMessage}>
              {formik.errors.name}
            </Text>
          ) : null}
          {addTrigger && (
            <div sx={css.fieldDiv}>
              <Text variant="subhead" sx={css.textHeader}>
                Github URL
              </Text>
              {githubTrigger && (
                <CopyableValue
                  value={`${process.env.REACT_APP_API_SERVER}${githubTrigger.route}`}
                  shorten={false}
                />
              )}
              {!githubTrigger && (
                <Button type="button" variant="github" onClick={addTrigger}>
                  Generate Github Webhook
                </Button>
              )}
            </div>
          )}
          <div sx={css.fieldDiv}>
            <Text variant="subhead" sx={css.textHeader}>
              What days are your standups?
            </Text>
            <DayPicker
              selectedDays={formik.values.days}
              onDayChange={(value) => {
                handleDayChange(value, formik);
              }}
            />
            {formik.errors.days && formik.touched.days ? (
              <Text variant="small" sx={css.errorMessage}>
                {formik.errors.days}
              </Text>
            ) : null}
            <Flex
              sx={{
                marginTop: 4,
                width: "100%",
                flexDirection: ["column", "row", "row"],
              }}
            >
              <Flex
                sx={{
                  width: ["100%", 8, 8],
                  marginRight: [null, 5, 5],
                  marginBottom: [3, null, null],
                  flexDirection: "column",
                }}
              >
                <Dropdown
                  value={formik.values.time}
                  onSelect={(value) => {
                    formik.setFieldValue("time", value);
                  }}
                  options={getTimes()}
                />
                {formik.errors.time && formik.touched.time ? (
                  <Text variant="small" sx={css.errorMessage}>
                    {formik.errors.time}
                  </Text>
                ) : null}
              </Flex>
              <Flex sx={{ width: ["100%", 9, 9], flexDirection: "column" }}>
                <Dropdown
                  value={formik.values.timezone}
                  onSelect={(value) => formik.setFieldValue("timezone", value)}
                  options={getTimeZones()}
                />
                {formik.errors.timezone && formik.touched.timezone ? (
                  <Text variant="small" sx={css.errorMessage}>
                    {formik.errors.timezone}
                  </Text>
                ) : null}
              </Flex>
            </Flex>
          </div>
          <div sx={css.fieldDiv}>
            <Text variant="subhead" sx={css.textHeader}>
              Add your Project Vitals:
            </Text>
            <Text variant="default" sx={css.textBody}>
              Add the video conference link (if applicable) and phone number
              with call in code here so everyone knows where to go for standup.
            </Text>
            <Flex
              sx={{
                ...css.flexDiv,
                flexDirection: ["column", "row", "row"],
                flexWrap: [null, "wrap", "wrap"],
              }}
            >
              <Flex
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: ["100%", "45%", "45%"],
                  marginBottom: 3,
                }}
              >
                <FancyInput
                  label="Video Conference URL"
                  placeholder="Paste URL"
                  value={formik.values.meeting_url}
                  onChange={formik.handleChange("meeting_url")}
                />
                {formik.errors.meeting_url && formik.touched.meeting_url ? (
                  <Text variant="small" sx={css.errorMessage}>
                    {formik.errors.meeting_url}
                  </Text>
                ) : null}
              </Flex>
              <Flex
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: ["100%", "45%", "45%"],
                  marginBottom: 3,
                }}
              >
                <FancyInput
                  label="Meeting Pin"
                  placeholder="Paste meeting PIN here"
                  value={formik.values.pin}
                  onChange={formik.handleChange("pin")}
                />
                {formik.errors.pin && formik.touched.pin ? (
                  <Text variant="small" sx={css.errorMessage}>
                    {formik.errors.pin}
                  </Text>
                ) : null}
              </Flex>
              <Flex
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: ["100%", "45%", "45%"],
                  marginBottom: 3,
                }}
              >
                <FancyInput
                  type="tel"
                  label="Phone Number"
                  placeholder="1 (123)-456-7890"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange("phone_number")}
                />
                {formik.errors.phone_number && formik.touched.phone_number ? (
                  <Text variant="small" sx={css.errorMessage}>
                    {formik.errors.phone_number}
                  </Text>
                ) : null}
              </Flex>
              <Flex
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: ["100%", "45%", "45%"],
                }}
              >
                <FancyInput
                  label="Call in Code"
                  placeholder="Paste call PIN code here"
                  value={formik.values.access_code}
                  onChange={formik.handleChange("access_code")}
                />
                {formik.errors.access_code && formik.touched.access_code ? (
                  <Text variant="small" sx={css.errorMessage}>
                    {formik.errors.access_code}
                  </Text>
                ) : null}
              </Flex>
            </Flex>
          </div>
          {children}
        </form>
      )}
    </Formik>
  );
};

const css = {
  fieldDiv: {
    marginTop: [4, 7, 7],
  },
  flexDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  textHeader: {
    color: "dark.0",
    marginBottom: 4,
  },
  textBody: {
    color: "dark.1",
    marginBottom: 4,
  },
  errorMessage: {
    color: "red",
    marginTop: 1,
    marginLeft: 2,
  },
};

ProjectForm.propTypes = {
  initValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
  addTrigger: PropTypes.func,
  triggers: PropTypes.arrayOf(PropTypes.object),
};

export default ProjectForm;
