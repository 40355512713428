import { createSelector } from "@reduxjs/toolkit";
import { getCurrentLocalTime, getCurrentProjectTime } from "utils/times";
import { localizeDays } from "utils/days";
import { path, pathOr, sortWith, descend, prop } from "ramda";

export const selectProfile = ({ store }) => store.profile;
export const selectStandups = ({ store }) => store.standups;
export const selectUsers = ({ store }) => store.users;
export const selectEntities = ({ store }) => store.entities;
export const selectProjects = ({ store }) => store.projects;
export const selectCompose = ({ store }) => store.compose;
export const selectIntegrations = ({ store }) => store.integrations;
export const selectTasks = ({ store }) => store.tasks;
export const selectDnd = ({ store }) => store.dnd;
export const selectAuth = ({ store }) => store.auth;
export const selectSearch = ({ store }) => store.search;

export const selectProjectUuid = ({ projectUuid }) => projectUuid;
export const selectInputDate = ({ date }) => date;

export const selectIsAuthenticated = createSelector(
  selectAuth,
  (auth) => auth.isAuthenticated
);

export const selectAuthMessage = createSelector(
  selectAuth,
  (auth) => auth.message
);

export const selectProfileId = createSelector(selectProfile, (profile) =>
  path(["id"], profile)
);

export const selectActiveProfile = createSelector(
  selectEntities,
  selectProfileId,
  (entities, profileId) => pathOr({}, ["user", profileId], entities)
);

export const selectActiveProject = createSelector(
  selectEntities,
  selectProjectUuid,
  selectUsers,
  selectProfileId,
  (entities, projectUuid, users, profileId) => {
    const project = pathOr({}, ["project", projectUuid], entities);

    const projectUsers = pathOr([], [project.id, "users"], users)
      .map((u) => path(["user", u], entities))
      .filter((u) => u !== profileId);

    return {
      ...project,
      users: projectUsers,
    };
  }
);

export const selectProject = createSelector(
  selectEntities,
  selectProjectUuid,
  (entities, projectUuid) => pathOr({}, ["project", projectUuid], entities)
);

export const selectProjectDays = createSelector(
  selectProject,
  (project) => project.days
);

export const selectProjectTimezone = createSelector(
  selectProject,
  (project) => project.timezone
);

export const selectAdjustedDays = createSelector(
  selectProjectDays,
  selectProjectTimezone,
  selectInputDate,
  (days, timezone, date) => {
    if (!days) return [];
    const currentMoment = getCurrentLocalTime(date);
    const projectTime = getCurrentProjectTime(timezone, currentMoment).startOf(
      "week"
    );
    return localizeDays(days, projectTime);
  }
);

export const selectUsersCompletedStandup = createSelector(
  selectStandups,
  selectActiveProject,
  selectInputDate,
  (standups, activeProject, serverDate) =>
    pathOr([], [serverDate, activeProject.id, "users"], standups)
);

export const selectActiveComposers = createSelector(
  selectUsers,
  selectActiveProject,
  selectProfileId,
  selectUsersCompletedStandup,
  selectEntities,
  (users, activeProject, profileId, usersCompletedStandup, entities) => {
    const projectUsers = pathOr([], [activeProject.id, "users"], users);

    const activeComposers = projectUsers
      .filter((uid) => uid !== profileId)
      .filter((uid) =>
        pathOr(
          false,
          [activeProject.id, "permissions", `${uid}`, "is_composer"],
          users
        )
      )
      .map((u) => {
        const user = path(["user", u], entities);
        return {
          ...user,
          completed: usersCompletedStandup.includes(u),
        };
      });

    return activeComposers;
  }
);

export const selectProjectUsers = createSelector(
  selectUsers,
  selectActiveProject,
  selectProfileId,
  selectEntities,
  (users, activeProject, profileId, entities) => {
    const projectUsers = pathOr([], [activeProject.id, "users"], users)
      .filter((uid) => uid !== profileId)
      .map((u) => path(["user", u], entities));

    return projectUsers;
  }
);

export const selectActiveProjectsList = createSelector(
  selectProjects,
  selectEntities,
  (projects, entities) => {
    const activeProjects = pathOr([], ["activeProjects"], projects);

    return activeProjects.map((ap) => entities.project[ap]);
  }
);

export const selectArchivedProjectsList = createSelector(
  selectProjects,
  selectEntities,
  (projects, entities) => {
    const archivedProjects = pathOr([], ["archivedProjects"], projects);

    return archivedProjects.map((ap) => entities.project[ap]);
  }
);

export const selectProjectListLoading = createSelector(
  selectProjects,
  (projects) => projects.isLoading
);

export const selectProfileLoading = createSelector(selectProfile, (profile) =>
  path(["isLoading"], profile)
);

export const selectComposeDraft = createSelector(
  selectProject,
  selectCompose,
  (project, compose) => compose[project.id]
);

export const selectStandupsLoading = createSelector(
  selectStandups,
  (standups) => path(["isLoading"], standups)
);

export const selectStandupTasks = createSelector(
  selectStandups,
  selectProject,
  selectProfileId,
  selectInputDate,
  selectEntities,
  (standups, project, profileId, date, entities) => {
    const tasks = pathOr(
      [],
      [date, project.id, "userTasks", profileId],
      standups
    );

    return tasks.map((t) => {
      const task = path(["task", t], entities);

      const taskEvents = pathOr([], ["events"], task)
        .map((e) => path(["event", e], entities))
        .filter((e) => !!e);

      return {
        ...task,
        events: taskEvents,
      };
    });
  }
);

export const selectAllStandupTasks = createSelector(
  selectStandups,
  selectInputDate,
  selectProject,
  selectEntities,
  (standups, serverDate, project, entities) => {
    const tasks = pathOr([], [serverDate, project.id, "tasks"], standups);

    return tasks.map((t) => {
      const task = pathOr({}, ["task", t], entities);

      const user = pathOr({}, ["user", task.userId], entities);

      const taskEvents = pathOr([], ["events"], task)
        .map((e) => path(["event", e], entities))
        .filter((e) => !!e);

      return {
        ...task,
        events: taskEvents,
        user,
      };
    });
  }
);

export const selectAllTasksByUser = createSelector(
  selectStandups,
  selectInputDate,
  selectActiveProject,
  selectUsers,
  selectEntities,
  (standups, serverDate, project, users, entities) => {
    const projectUserIds = pathOr([], [project.id, "users"], users);

    const tasksByUser = projectUserIds.map((id) => {
      const user = path(["user", id], entities);

      const tasks = pathOr(
        [],
        [serverDate, project.id, "userTasks", id],
        standups
      ).map((t) => {
        const task = path(["task", t], entities);

        const events = pathOr([], ["events"], task)
          .map((e) => path(["event", e], entities))
          .filter((e) => !!e);

        return {
          ...task,
          events,
        };
      });

      return {
        ...user,
        tasks,
      };
    });

    return sortWith([descend(prop("tasks"))], tasksByUser);
  }
);

export const selectIntegrationEvents = createSelector(
  selectIntegrations,
  selectProject,
  selectEntities,
  selectInputDate,
  (integrations, project, entities, date) => {
    const eventIDs = pathOr([], [date, project.id], integrations);

    return eventIDs.map((id) => entities.event[id]);
  }
);

export const selectCurrentUserIsComposer = createSelector(
  selectUsers,
  selectProject,
  selectProfileId,
  (users, project, profileId) =>
    pathOr(false, [project.id, "permissions", profileId, "is_composer"], users)
);

export const selectSearchResults = createSelector(
  selectProject,
  selectEntities,
  selectSearch,
  selectUsers,
  (project, entities, results, users) => {
    const activeUsersIds = pathOr([], [project.id, "users"], users);

    const searchUsers = results.users
      .filter((uid) => !activeUsersIds.includes(uid))
      .map((u) => entities.user[u]);

    return searchUsers;
  }
);
