/** @jsx jsx */
import { jsx, Button, Text, Flex, Spinner, Box } from "theme-ui";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { updatePassword, updateProfile } from "redux/modules/profile";
import { logout } from "redux/modules/auth";
import Page from "containers/Page";
import FancyInput from "components/FancyInput";
import Main from "components/Main";
import Header from "components/Header";
import { selectActiveProfile, selectProfileLoading } from "redux/selectors";

const Settings = ({ history }) => {
  const [name, setName] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [passwordError, setPasswordError] = useState(false);

  const dispatch = useDispatch();

  const profile = useSelector((store) => selectActiveProfile({ store }));
  const isLoading = useSelector((store) => selectProfileLoading({ store }));

  useEffect(() => {
    if (profile.name) {
      setName(profile.name);
    }
  }, [profile]);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();

    dispatch(updateProfile({ name }));
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    if (newPassword !== currentPassword) {
      setPasswordError(false);
      dispatch(updatePassword(currentPassword, newPassword));
    } else {
      setPasswordError(true);
    }

    setNewPassword("");
    setCurrentPassword("");
  };

  return (
    <Page title="StandupHub | Settings">
      <Header text="Settings" />
      <Main>
        <Box mx={[4, 0, 0]}>
          {isLoading && (
            <Flex sx={{ justifyContent: "center" }}>
              <Spinner />
            </Flex>
          )}
          <form onSubmit={handleUpdateProfile}>
            <div sx={sx.container}>
              <Text variant="subhead" sx={sx.title}>
                Profile
              </Text>
              <FancyInput
                variant="composer"
                type="text"
                label="Name"
                sx={sx.input}
                value={name}
                onChange={({ target }) => setName(target.value)}
                required
              />
              <div sx={sx.actions}>
                <Button variant="primary" type="submit" sx={{ mr: 3 }}>
                  Save
                </Button>
                <Button
                  type="button"
                  variant="minimal"
                  color="blue"
                  onClick={() => history.push("/")}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
          {profile.has_password && (
            <div sx={sx.container}>
              <Text variant="subhead" sx={sx.title}>
                Change Password
              </Text>
              {passwordError && (
                <div
                  sx={{ mb: 3, color: (theme) => theme.colors.red }}
                  className="flash-message flash-error flash-align-left"
                >
                  Passwords should not match
                </div>
              )}
              <form onSubmit={handlePasswordChange}>
                <FancyInput
                  sx={sx.input}
                  variant="composer"
                  type="password"
                  label="Current Password"
                  value={currentPassword}
                  onChange={({ target }) => setCurrentPassword(target.value)}
                />
                <FancyInput
                  sx={sx.input}
                  variant="composer"
                  type="password"
                  label="New Password"
                  value={newPassword}
                  onChange={({ target }) => setNewPassword(target.value)}
                />
                <div sx={sx.actions}>
                  <Button
                    disabled={!newPassword.length && !currentPassword.length}
                    variant="primary"
                    type="submit"
                    sx={{ mr: 3 }}
                  >
                    Update Password
                  </Button>
                  <Button
                    type="button"
                    variant="minimal"
                    color="blue"
                    onClick={() => history.push("/")}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          )}
          <div sx={sx.actions}>
            <Button variant="navigation" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </Box>
      </Main>
    </Page>
  );
};

const sx = {
  actions: {
    marginTop: 5,
  },
  container: {
    marginTop: [5, 7, 7],
  },
  title: {
    mb: 3,
  },
  input: {
    marginTop: 3,
  },
};

Settings.propTypes = {
  history: PropTypes.object,
};

export default Settings;
