/** @jsx jsx */
import { Fragment } from "react";
import { jsx, Text, Flex, Spinner, Box } from "theme-ui";
import PropTypes from "prop-types";
import { getDetails } from "utils/details";
import { buildProjectUrl } from "utils/projectUrl";
import ProjectRow from "components/ProjectRow";
import Main from "components/Main";
import Header from "components/Header";
import getProjectColor from "utils/colors";

const ProjectList = ({
  activeProjects,
  archivedProjects,
  isLoading,
  headerAction,
  title,
}) => (
  <Fragment>
    <Header text={title} action={headerAction} />
    <Main>
      <Box mx={[4, 0, 0]}>
        {!activeProjects && !isLoading && (
          <Text>You don't have any projects yet.</Text>
        )}
        {isLoading && (
          <Flex sx={{ justifyContent: "center" }}>
            <Spinner />
          </Flex>
        )}
        <Flex
          ml="auto"
          mr="auto"
          sx={{
            flexDirection: "column",
          }}
        >
          <h3>Active Projects</h3>
          <Box sx={{ marginBottom: 4 }}>
            {activeProjects.map((project, i) => (
              <ProjectRow
                key={i}
                name={project.name}
                when={getDetails(project.days, project.time, project.timezone)}
                color={getProjectColor(project.id)}
                to={`${buildProjectUrl(project)}${
                  project.is_composer ? "/me" : ""
                }`}
                edit={`${buildProjectUrl(project)}/edit`}
              />
            ))}
          </Box>
          {archivedProjects && !!archivedProjects.length && (
            <Box>
              <h3>Archived Projects</h3>
              {archivedProjects.map((project, i) => (
                <ProjectRow
                  key={i}
                  name={project.name}
                  id={project.id}
                  when={getDetails(
                    project.days,
                    project.time,
                    project.timezone
                  )}
                  color={getProjectColor(project.id)}
                  isArchived
                />
              ))}
            </Box>
          )}
        </Flex>
      </Box>
    </Main>
  </Fragment>
);

ProjectList.propTypes = {
  activeProjects: PropTypes.arrayOf(PropTypes.object),
  archivedProjects: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  headerAction: PropTypes.node,
  title: PropTypes.string,
};

export default ProjectList;
