import { API_VERSION } from "constants/api";
import { User } from "schemas";
import { success, error } from "utils/notifications";

export const PROFILE_LOAD = "PROFILE_LOAD";
export const PROFILE_LOAD_SUCCESS = "PROFILE_LOAD_SUCCESS";
export const PROFILE_LOAD_FAILURE = "PROFILE_LOAD_FAILURE";

export const PROFILE_UPDATE_LOAD = "PROFILE_UPDATE_LOAD";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAILURE = "PROFILE_UPDATE_FAILURE";

export const PROFILE_UPDATE_PASSWORD_LOAD = "PROFILE_UPDATE_PASSWORD_LOAD";
export const PROFILE_UPDATE_PASSWORD_SUCCESS =
  "PROFILE_UPDATE_PASSWORD_SUCCESS";
export const PROFILE_UPDATE_PASSWORD_FAILURE =
  "PROFILE_UPDATE_PASSWORD_FAILURE";

const initialState = {
  id: 0,
  isLoading: false,
};

export default function reducer(user = initialState, action = {}) {
  switch (action.type) {
    case PROFILE_LOAD:
    case PROFILE_UPDATE_LOAD:
      return { ...user, isLoading: true };
    case PROFILE_LOAD_SUCCESS:
    case PROFILE_UPDATE_SUCCESS:
      const id = action.response.result;
      return { ...user, id, isLoading: false };
    default:
      return user;
  }
}

export function getProfile() {
  return {
    types: [PROFILE_LOAD, PROFILE_LOAD_SUCCESS, PROFILE_LOAD_FAILURE],
    request: (api) => api.get(`/${API_VERSION}/account`),
    schema: User,
  };
}

export function updateProfile(account = {}) {
  return {
    types: [
      PROFILE_UPDATE_LOAD,
      PROFILE_UPDATE_SUCCESS,
      PROFILE_UPDATE_FAILURE,
    ],
    request: (api) => {
      return api
        .put(`/${API_VERSION}/account`, {
          body: JSON.stringify(account),
        })
        .then((response) => {
          success("Profile was updated");
          return response;
        })
        .catch((e) => {
          error("There was a problem updating the profile");
          return e;
        });
    },
    schema: User,
  };
}

export function updatePassword(currentPassword, newPassword) {
  return {
    types: [
      PROFILE_UPDATE_PASSWORD_LOAD,
      PROFILE_UPDATE_PASSWORD_SUCCESS,
      PROFILE_UPDATE_PASSWORD_FAILURE,
    ],
    request: (api) => {
      return api
        .post(`/${API_VERSION}/account/password/update`, {
          body: JSON.stringify({
            current_password: currentPassword,
            new_password: newPassword,
          }),
        })
        .then((response) => {
          success("Password successfully updated");
          return response;
        })
        .catch((e) => {
          error("There was a problem updating the profile");
          return e;
        });
    },
    schema: User,
  };
}
