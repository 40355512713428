/** @jsx jsx */
import { jsx } from "theme-ui";
import { alpha } from "@theme-ui/color";

export default (props) => (
  <label disabled={props.hidden} htmlFor={props.identifier} sx={css.label}>
    {props.label}
    <input
      disabled={props.hidden}
      id={props.identifier}
      type="radio"
      sx={{ appearance: "none", position: "relative" }}
      onClick={props.onSelect}
    />
  </label>
);

const css = {
  label: {
    alignItems: "center",
    fontSize: "body",
    borderRadius: 1,
    display: "flex",
    justifyContent: "center",
    position: "relative",
    width: "30%",
    cursor: "pointer",
    "&:hover": {
      background: alpha("dark.0", 0.05),
      mixBlendMode: "multiply",
    },
    "&:focus-within": {
      outline: "1px solid",
      outlineColor: alpha("dark.0", 0.2),
    },
  },
};
