import moment from "moment-timezone";

export const CLIENT_TIME_TIMEZONE_FORMAT = "hh:mm a z";
export const CLIENT_TIME_FORMAT = "hh:mm a";

const TIME_INTERVAL = 15;

function convertIntToTime(time) {
  if (time >= 0 && time < 1440) {
    return moment()
      .startOf("day")
      .add(time, "minutes")
      .format(CLIENT_TIME_FORMAT);
  }
}

export function getTimes() {
  const times = [];
  let time = 0;

  while (time < 1440) {
    const newTime = convertIntToTime(time);
    times.push({ label: newTime, value: time });
    time += TIME_INTERVAL;
  }

  return times;
}

// we need to set the current timezone, otherwise moment won't
// do the correct calculation when switching to the project timezone
export const getCurrentLocalTime = (date) => moment(date).tz(moment.tz.guess());

/**
 * getCurrentProjectTime
 * timezone: optional string formatted name of timezone
 * date: optional moment date object
 */
export const getCurrentProjectTime = (
  timezone = moment.tz.guess(),
  date = undefined
) => getCurrentLocalTime(date).tz(timezone);

/**
 * getProjectTime
 * time: required integer minute offset time of the standup
 * timezone: optional string formatted name of timezone,
 *           defaults to the local timezone
 *
 * returns a moment object representing the time of a standup for
 * the current day in the project timezone
 */
export const getProjectTime = (time, timezone) =>
  getCurrentProjectTime(timezone).startOf("day").add(time, "minutes");

/**
 * convertToUserTimezone
 * time: required integer minute offset time of the standup
 * timezone: optional string formatted name of timezone,
 *           defaults to the local timezone
 *
 * returns a moment object representing the time of a standup for
 * the current day in the user's local timezone
 */
export function convertToUserTimezone(time, timezone) {
  const oldTimezone = getProjectTime(time, timezone);
  return oldTimezone.tz(moment.tz.guess());
}

export function getTimeZones() {
  return moment.tz.names().map((n) => {
    return { label: n, value: n };
  });
}
