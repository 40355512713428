/** @jsx jsx*/
import { jsx } from "theme-ui";
import { lightness } from "@theme-ui/color";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

import { STATUSES, STATUS_LABELS } from "constants/api";
import Toggle from "./toggle";

const positions = {
  /** Sacred Math:
   * to center the bg element, we need to shift it right its full width plus
   * account for the gutter between each of the 30% width buttons so that the
   * bg is visually aligned with the button elements.
   *
   * 30% button | 5% gutter | 30%button | 5% gutter | 30% button
   *
   * to get to the center we need to go 35% to the right
   * or
   * Center Offset = 100% + (5% / 30% )
   * Center Offset = 116.6%
   *
   * Right Offset = 100% + (5% / 30%) + 100% + (5% / 30%)
   * Right OFfset = 233.3%
   *
   **/
  center: {
    x: "116.6%",
  },
  left: {
    x: 0,
  },
  right: {
    x: "233.3%",
  },
};

function fromStatus(state) {
  switch (state) {
    case "blocked":
      return {
        color: "red",
        position: "right",
      };
    case "complete":
      return {
        color: "green",
        position: "left",
      };
    case "in-progress":
      return {
        color: "cyan",
        position: "center",
      };
    default:
      break;
  }
}

export default function Toggles({ active, hidden, onChange }) {
  const handleStatusChange = (status) => () => {
    onChange(status);
  };

  return (
    <div
      sx={{
        ...css.toggleRow,
        background: lightness(fromStatus(active).color, 0.9),
        pointerEvents: hidden ? "none" : "initial",
      }}
    >
      <div
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <motion.div
          variants={positions}
          initial="center"
          animate={fromStatus(active).position}
          transition={{
            type: "spring",
            stiffness: 250,
            velocity: 500,
            damping: 20,
          }}
          sx={{
            ...css.toggleBg,
            bg: fromStatus(active).color,
          }}
        ></motion.div>
        <div role="radiogroup" sx={css.radiogroup}>
          {STATUSES.map((s, i) => (
            <Toggle
              key={i}
              hidden={hidden}
              label={STATUS_LABELS[s]}
              identifier={`${s}-toggle`}
              onSelect={handleStatusChange(s)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const css = {
  radiogroup: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
  },
  toggleBg: {
    height: [32, 40],
    borderRadius: 1,
    position: "absolute",
    width: "30%",
  },
  toggleRow: {
    borderRadius: 1,
    display: "flex",
    height: [40, 48],
    padding: 1,
  },
};

Toggles.propTypes = {
  onChange: PropTypes.func.isRequired,
};
