/** @jsx jsx */
import { jsx, Box, Flex, Spinner, Avatar, Text } from "theme-ui";
import moment from "moment";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import isEmptyOrNil from "utils/isEmptyOrNil";
import {
  selectAllStandupTasks,
  selectProjectListLoading,
  selectCurrentUserIsComposer,
  selectAllTasksByUser,
} from "redux/selectors";
import { Link, useLocation, useParams } from "react-router-dom";
import { SERVER_DATE_FORMAT } from "constants/api";
import { parse } from "query-string";
import TaskGroup from "components/TaskGroup";
import PropTypes from "prop-types";
import { buildProjectUrl } from "utils/projectUrl";
import FormattedDate from "components/FormattedDate";
import SelectView from "components/SelectView";
import Main from "components/Main";
import Aside from "components/Aside";
import TeammateList from "components/TeammateList";

const Team = ({ project = {}, composers = [] }) => {
  const [userIdFilter, setUserIdFilter] = useState(undefined);
  const { search } = useLocation();
  const { projectUuid } = useParams();

  const query = parse(search);
  const date = moment(query.date).format(SERVER_DATE_FORMAT);

  const isLoading = useSelector((store) =>
    selectProjectListLoading({ store, projectUuid })
  );

  const tasks = useSelector((store) =>
    selectAllStandupTasks({ store, date, projectUuid })
  );

  const tasksByUser = useSelector((store) =>
    selectAllTasksByUser({ store, date, projectUuid })
  );

  const currentUserIsComposer = useSelector((store) =>
    selectCurrentUserIsComposer({
      store,
      projectUuid,
    })
  );

  const handleOnClick = (userId) => () => setUserIdFilter(userId);

  const projectUrl = buildProjectUrl(project);

  const filteredUsers = userIdFilter
    ? tasksByUser.filter((user) => user.id === userIdFilter)
    : tasksByUser;

  return (
    <Fragment>
      <Main>
        <Box mx={[4, 0, 0]}>
          <Flex sx={{ justifyContent: "space-between", marginBottom: 4 }}>
            <FormattedDate sx={{ flex: 1 }} date={date} />
            <SelectView
              projectUrl={projectUrl}
              date={date}
              isComposer={currentUserIsComposer}
            />
          </Flex>
          {isLoading && (
            <Flex sx={{ justifyContent: "center", marginTop: 4 }}>
              <Spinner />
            </Flex>
          )}
          {isEmptyOrNil(tasks) && !isLoading && (
            <Box sx={{ marginBottom: 4 }}>
              No standups have been reported for today.&nbsp;
              {currentUserIsComposer && (
                <Link
                  to={{
                    pathname: `${projectUrl}/me`,
                    query: { date },
                  }}
                >
                  Add one
                </Link>
              )}
            </Box>
          )}
          {filteredUsers.map(({ tasks, id, name, avatar }) => {
            const hasTasks = !!tasks.length;
            return (
              <Flex key={id} sx={{ flexDirection: "column", marginBottom: 3 }}>
                <Flex
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    opacity: hasTasks ? 1 : 0.5,
                    marginBottom: 3,
                    justifyContent: "space-between",
                  }}
                >
                  <Flex sx={{ alignItems: "center" }}>
                    <Box sx={{ minWidth: 5, marginRight: 2 }}>
                      <Avatar src={avatar} alt={name} title={name} />
                    </Box>
                    <Text>{name}</Text>
                  </Flex>
                  {!hasTasks && (
                    <Text
                      sx={{
                        color: "dark.1",
                        fontStyle: "italic",
                      }}
                    >
                      no standups yet
                    </Text>
                  )}
                </Flex>
                <Flex sx={{ marginLeft: 3, flexDirection: "column" }}>
                  {hasTasks && <TaskGroup tasks={tasks} readOnly={true} />}
                </Flex>
              </Flex>
            );
          })}
        </Box>
      </Main>
      {project && (
        <Aside>
          <TeammateList
            composers={composers}
            project={project}
            onClick={handleOnClick}
          />
        </Aside>
      )}
    </Fragment>
  );
};

Team.propTypes = {
  project: PropTypes.object,
  composers: PropTypes.arrayOf(PropTypes.object),
};

export default Team;
