import moment from "moment";
import {
  API_VERSION,
  CLIENT_DATE_FORMAT,
  SERVER_DATE_FORMAT,
  STATUS_COMPLETE,
} from "constants/api";
import { Task } from "schemas";
import { getStandups, getStandupForUser } from "./standups";

export const TASK_CREATE = "TASK_CREATE";
export const TASK_CREATE_SUCCESS = "TASK_CREATE_SUCCESS";
export const TASK_CREATE_FAILURE = "TASK_CREATE_FAILURE";

export const TASK_REMOVE = "TASK_REMOVE";
export const TASK_REMOVE_SUCCESS = "TASK_REMOVE_SUCCESS";
export const TASK_REMOVE_FAILURE = "TASK_REMOVE_FAILURE";

export const TASKS_UPDATE = "TASKS_UPDATE";
export const TASKS_UPDATE_SUCCESS = "TASKS_UPDATE_SUCCESS";
export const TASKS_UPDATE_FAILURE = "TASKS_UPDATE_FAILURE";

export const TASK_MOVE = "TASK_MOVE";
export const TASK_MOVE_SUCCESS = "TASK_MOVE_SUCCESS";
export const TASK_MOVE_FAILURE = "TASK_MOVE_FAILURE";

export function createTask(
  task = {},
  projectId = "",
  userId = "",
  date = moment().format(CLIENT_DATE_FORMAT)
) {
  const serverDate = moment(date).format(SERVER_DATE_FORMAT);

  const hasEvents = task.events && !!task.events.length;

  const newTask = {
    title: task.title,
    status: task.status || STATUS_COMPLETE,
    events: hasEvents ? task.events.map((e) => e.id) : undefined,
    notes: task.notes,
  };

  return {
    types: [TASK_CREATE, TASK_CREATE_SUCCESS, TASK_CREATE_FAILURE],
    request: (api) =>
      api.post(`/${API_VERSION}/standups/${serverDate}/${projectId}`, {
        body: JSON.stringify(newTask),
      }),
    schema: Task,
    date: serverDate,
    projectId,
    userId,
  };
}

export function updateTaskById(id = "", task = {}) {
  return {
    types: [TASKS_UPDATE, TASKS_UPDATE_SUCCESS, TASKS_UPDATE_FAILURE],
    request: (api) =>
      api.put(`/${API_VERSION}/tasks/${id}`, { body: JSON.stringify(task) }),
    schema: Task,
  };
}

export function removeTaskById(
  taskId = "",
  projectId = "",
  date = moment().format(CLIENT_DATE_FORMAT)
) {
  return (dispatch) => {
    return dispatch({
      types: [TASK_REMOVE, TASK_REMOVE_SUCCESS, TASK_REMOVE_FAILURE],
      request: (api) => {
        return api.del(`/${API_VERSION}/tasks/${taskId}`).then((response) => {
          dispatch(getStandups(projectId, date));
          return response;
        });
      },
      schema: Task,
    });
  };
}

export function reorderTask(newPosition, task = {}) {
  return (dispatch) => {
    return dispatch({
      types: [TASK_MOVE, TASK_MOVE_SUCCESS, TASK_MOVE_FAILURE],
      request: (api) => {
        return api
          .put(`/${API_VERSION}/tasks/reorder/${task.id}`, {
            body: JSON.stringify({
              ...task,
              standup_date: task.date,
              position: newPosition,
            }),
          })
          .then(() => {
            dispatch(
              getStandupForUser(
                task.project_id,
                task.user_id,
                task.date.toString()
              )
            );
          });
      },
    });
  };
}
