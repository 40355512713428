import { API_UNAUTHORIZED } from "constants/auth";
import { RESET_PASSWORD_LOAD_FAILURE } from "redux/modules/password";
import { LOCATION_CHANGE, routerActions } from "connected-react-router";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const ERR_CONNECTION_REFUSED = "ERR_CONNECTION_REFUSED";

export const CLEAR_AUTH_MESSAGE = "CLEAR_AUTH_MESSAGE";

export const SIGNUP_LOAD = "SIGNUP_LOAD";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

const initialState = {
  message: "",
  token: "",
  isAuthenticated: false,
};

export default function reducer(auth = initialState, action = {}) {
  switch (action.type) {
    case SIGNUP_LOAD:
    case SIGNUP_SUCCESS:
    case LOCATION_CHANGE:
      return { ...auth, message: "" };
    case SIGNUP_FAILURE:
      return { ...auth, message: action.response.parsedBody.error };
    case API_UNAUTHORIZED:
      return {
        ...auth,
        token: "",
        isAuthenticated: false,
      };
    case LOGIN:
      return {
        ...auth,
        token: action.token,
        isAuthenticated: true,
        message: "",
      };
    case LOGOUT:
      return { ...auth, token: "", isAuthenticated: false };
    case AUTH_FAILURE:
      const message =
        action.error.status === ERR_CONNECTION_REFUSED
          ? "Authorization failed. Please try again later."
          : "Incorrect username or password";

      return {
        ...auth,
        token: "",
        isAuthenticated: false,
        message,
      };
    case RESET_PASSWORD_LOAD_FAILURE:
      return {
        ...auth,
        message: action.response.parsedBody.message,
      };
    case CLEAR_AUTH_MESSAGE:
      return {
        ...auth,
        message: "",
      };
    default:
      return auth;
  }
}

export function loginSuccess(token = "", redirect) {
  window.localStorage.setItem("token", token);
  return (dispatch) => {
    dispatch({
      type: LOGIN,
      token,
    });
    if (redirect) {
      dispatch(routerActions.push(redirect));
    }
  };
}

export function login(email = "", password = "", redirect = "/") {
  return (dispatch) => {
    return dispatch({
      types: [AUTH_LOGIN, AUTH_SUCCESS, AUTH_FAILURE, ERR_CONNECTION_REFUSED],
      request: (api) => {
        return api
          .post("/auth", { body: JSON.stringify({ email, password }) })
          .then((response) => {
            dispatch(loginSuccess(response.parsedBody.token, redirect));
            return response;
          });
      },
    });
  };
}

export function clearAuthMessage() {
  return (dispatch) => {
    return dispatch({
      type: CLEAR_AUTH_MESSAGE,
    });
  };
}

export function signup(
  { name, email, password, passwordConfirmation },
  redirect = "/"
) {
  return (dispatch) => {
    return dispatch({
      types: [SIGNUP_LOAD, SIGNUP_SUCCESS, SIGNUP_FAILURE],
      request: (api) => {
        return api
          .post("/signup", {
            body: JSON.stringify({
              name,
              email,
              password,
              password_confirmation: passwordConfirmation,
            }),
          })
          .then((response) => {
            dispatch(loginSuccess(response.parsedBody.token, redirect));
            return response;
          });
      },
    });
  };
}

export function logout() {
  window.localStorage.removeItem("token");
  return {
    type: LOGOUT,
  };
}

export function logoutAndRedirect(redirect = "/login") {
  return (dispatch) => {
    dispatch(logout());
    dispatch(routerActions.push(redirect));
  };
}
